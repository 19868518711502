
import React, {useState} from "react";
import {Button, Form, Input, Select} from "antd";
import {TabSwitcher} from "../../../components/src/layout/component/TabSwitcher";
import { Breadcrumb } from "../../../components";
export const FundAccount = () => {
    const [currentTab, setCurrentTab] = useState('Bank transfer')
    const [beneficiary, setBeneficiary] = React.useState("");
    const [amount, setAmount] = React.useState("");

    const handleFundAccount = () => {
        // TODO: Implement this function to fund the account.
    };
    const links = [
        {
            name: 'Agent Details',
            path: '/dashboard'
        },
        {
            name: 'Fund Account',
            path: ''
        }
    ]

    const tabs = ['Bank transfer', 'My Account'];

    return (

        <div className={'fundAccount'}>
            <div>
                <Breadcrumb links={links}/>
            </div>
            <div className={'fundAccountMain'}>

                <TabSwitcher tabs={tabs} onTabClick={(selectedTab: any) => setCurrentTab(selectedTab)} />

                {
                    currentTab === 'Bank transfer' ?
                        <div className={'copyAccountDetails'}>
                            <div>
                                Fund account through bank transfer using the details below
                                and get credited instantly
                            </div>

                            <div>
                                <div className={'accountInfoRow'}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M23.0733 10.4685C23.969 10.4685 24.3429 9.25314 23.6153 8.6929L12.5449 0.188277C12.3868 0.0659484 12.1959 0 11.9999 0C11.8039 0 11.6131 0.0659484 11.455 0.188277L0.38451 8.6929C-0.343084 9.25008 0.0308585 10.4685 0.929481 10.4685H2.72383V21.9182H0.636704C0.509157 21.9182 0.404801 22.0284 0.404801 22.1631V23.7551C0.404801 23.8898 0.509157 24 0.636704 24H23.3632C23.4907 24 23.5951 23.8898 23.5951 23.7551V22.1631C23.5951 22.0284 23.4907 21.9182 23.3632 21.9182H21.276V10.4685H23.0733ZM8.20253 21.9182H4.81095V10.4685H8.20253V21.9182ZM13.6812 21.9182H10.2896V10.4685H13.6812V21.9182ZM19.1889 21.9182H15.7683V10.4685H19.1889V21.9182Z"
                                            fill="#3B4EE4"/>
                                    </svg>
                                    <div>
                                        <div>BANK NAME</div>
                                        <div>Maestro MFB</div>
                                    </div>
                                </div>

                                <div className={'accountInfoRow'}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M23.7391 8.15217C23.8826 8.15217 24 8.03478 24 7.8913V5.93478C24 5.7913 23.8826 5.67391 23.7391 5.67391H18.3913V0.26087C18.3913 0.117391 18.2739 0 18.1304 0H16.0435C15.9 0 15.7826 0.117391 15.7826 0.26087V5.67391H8.34783V0.26087C8.34783 0.117391 8.23043 0 8.08696 0H6C5.85652 0 5.73913 0.117391 5.73913 0.26087V5.67391H0.26087C0.117391 5.67391 0 5.7913 0 5.93478V7.8913C0 8.03478 0.117391 8.15217 0.26087 8.15217H5.73913V15.8478H0.26087C0.117391 15.8478 0 15.9652 0 16.1087V18.0652C0 18.2087 0.117391 18.3261 0.26087 18.3261H5.73913V23.7391C5.73913 23.8826 5.85652 24 6 24H8.08696C8.23043 24 8.34783 23.8826 8.34783 23.7391V18.3261H15.7826V23.7391C15.7826 23.8826 15.9 24 16.0435 24H18.1304C18.2739 24 18.3913 23.8826 18.3913 23.7391V18.3261H23.7391C23.8826 18.3261 24 18.2087 24 18.0652V16.1087C24 15.9652 23.8826 15.8478 23.7391 15.8478H18.3913V8.15217H23.7391ZM15.7826 15.8478H8.34783V8.15217H15.7826V15.8478Z"
                                            fill="#3B4EE4"/>
                                    </svg>

                                    <div>
                                        <div>ACCOUNT NUMBER</div>
                                        <div>
                                            <span>8017224918</span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9.83333 2.33301H3.5C3.36739 2.33301 3.24021 2.38569 3.14645 2.47945C3.05268 2.57322 3 2.7004 3 2.83301V10.833C3 10.9656 3.05268 11.0928 3.14645 11.1866C3.24021 11.2803 3.36739 11.333 3.5 11.333H9.83333C9.96594 11.333 10.0931 11.2803 10.1869 11.1866C10.2807 11.0928 10.3333 10.9656 10.3333 10.833V2.83301C10.3333 2.7004 10.2807 2.57322 10.1869 2.47945C10.0931 2.38569 9.96594 2.33301 9.83333 2.33301ZM9.66667 10.6663H3.66667V2.99967H9.66667V10.6663Z"
                                                    fill="#3B4EE4"/>
                                                <path
                                                    d="M8.66732 1.16699C8.66732 1.03438 8.61464 0.907207 8.52087 0.813439C8.4271 0.719671 8.29992 0.666992 8.16732 0.666992H1.83398C1.70138 0.666992 1.5742 0.719671 1.48043 0.813439C1.38666 0.907207 1.33398 1.03438 1.33398 1.16699V9.16699C1.33398 9.2996 1.38666 9.42678 1.48043 9.52055C1.5742 9.61431 1.70138 9.66699 1.83398 9.66699H2.00065V1.33366H8.66732V1.16699Z"
                                                    fill="#3B4EE4"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className={'accountInfoRow'}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M23.0733 10.4685C23.969 10.4685 24.3429 9.25314 23.6153 8.6929L12.5449 0.188277C12.3868 0.0659484 12.1959 0 11.9999 0C11.8039 0 11.6131 0.0659484 11.455 0.188277L0.38451 8.6929C-0.343084 9.25008 0.0308585 10.4685 0.929481 10.4685H2.72383V21.9182H0.636704C0.509157 21.9182 0.404801 22.0284 0.404801 22.1631V23.7551C0.404801 23.8898 0.509157 24 0.636704 24H23.3632C23.4907 24 23.5951 23.8898 23.5951 23.7551V22.1631C23.5951 22.0284 23.4907 21.9182 23.3632 21.9182H21.276V10.4685H23.0733ZM8.20253 21.9182H4.81095V10.4685H8.20253V21.9182ZM13.6812 21.9182H10.2896V10.4685H13.6812V21.9182ZM19.1889 21.9182H15.7683V10.4685H19.1889V21.9182Z"
                                            fill="#3B4EE4"/>
                                    </svg>
                                    <div>
                                        <div>BANK NAME</div>
                                        <div>Maestro MFB</div>
                                    </div>
                                </div>


                            </div>
                        </div> :
                        <Form style={{backgroundColor: "white", padding: '35px'}} layout="vertical">
                            <Form.Item label="Beneficiary">
                                <Input
                                    value={beneficiary}
                                    onChange={(e) => setBeneficiary(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item label="Transfer from">
                                <Select>
                                    <Select.Option>Account 1</Select.Option>
                                    <Select.Option>Account 2</Select.Option>
                                    <Select.Option>Account 3</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Amount">
                                <Input
                                    type="number"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button style={{width: "100%"}} type="primary" onClick={handleFundAccount}>
                                    Proceed
                                </Button>
                            </Form.Item>
                        </Form>
                }
            </div>
        </div>
    );
};
