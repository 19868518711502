import React from "react";

export const formatAmount = (amount: number): string => {
  // Ensure the number has two decimal places
  const formattedAmount = amount.toFixed(2);

  // Add commas as thousand separators
  const parts = formattedAmount.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `₦` + parts.join(".");
};
export const formatAmountNoCurrency = (amount: number): string => {
  // Ensure the number has two decimal places
  const formattedAmount = amount.toFixed(2);

  // Add commas as thousand separators
  const parts = formattedAmount.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
};

export const amountToWords = (amount: number): string => {
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  const convertToWords = (num: number): string => {
    if (num === 0) {
      return "Zero";
    }

    if (num < 0) {
      return "Negative " + convertToWords(Math.abs(num));
    }

    let words = "";

    if (Math.floor(num / 1000000) > 0) {
      words += convertToWords(Math.floor(num / 1000000)) + " Million ";
      num %= 1000000;
    }

    if (Math.floor(num / 1000) > 0) {
      words += convertToWords(Math.floor(num / 1000)) + " Thousand ";
      num %= 1000;
    }

    if (Math.floor(num / 100) > 0) {
      words += convertToWords(Math.floor(num / 100)) + " Hundred ";
      num %= 100;
    }

    if (num > 0) {
      if (words !== "" && num > 0) {
        words += "and ";
      }

      if (num < 10) {
        words += units[num];
      } else if (num < 20) {
        words += teens[num - 10];
      } else {
        words += tens[Math.floor(num / 10)];
        if (num % 10 > 0) {
          words += "-" + units[num % 10];
        }
      }
    }

    return words.trim();
  };

  const words = convertToWords(amount);

  return words.charAt(0).toUpperCase() + words.slice(1).toLowerCase();
};
