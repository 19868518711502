import {dummyHead} from "../../../assets/images";
import {Button, Dropdown, Modal, Switch, Table} from "antd";
import {EmptyState} from "../../../components/src/layout/component/EmptyState";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

export const AccountDetails = () => {
    const [showSuspendAccount, setSuspendAccount] = useState(false)
    const navigate = useNavigate()

    const items: any = [
        {
            label: 'View transactions',
            key: 'viewTransactions',
            onClick: () => navigate('/dashboard/transaction-history')
        },
        {
            label: 'Found Account',
            key: 'foundAccount',
        },
        {
            label: 'Suspend',
            key: 'suspend',
            overlayClassName: 'suspended'
        },
        {
            label: 'Debit on hold',
            key: 'debitOnHold',
            overlayClassName: 'debitOnHold'
        }
    ];


    const dataSource: any[] = [
        {
            accountNo: '12345',
            accountName: 'John Doe',
            balance: 1000,
            dateCreated: '2023-12-07',
            status: 'Active',
        },
    ];

    for (let i = 1; i <= 20; i++) {
        dataSource.push({
            accountNo: `${Math.floor(Math.random() * 100000)}`,
            accountName: `User ${i}`,
            balance: Math.floor(Math.random() * 5000) + 500, // Random balance between 500 and 5500
            dateCreated: '2023-12-07', // Use the actual date or generate dynamically
            status: ['Active', 'Suspended', 'DebitOnHold'][Math.floor(Math.random() * 3)], // Random status
        });
    }

    const columns: any[] = [
        {
            title: 'Account No',
            dataIndex: 'accountNo',
            key: 'accountNo',
        },
        {
            title: 'Account Name',
            dataIndex: 'accountName',
            key: 'accountName',
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: 'Date Created',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: any, record: any): any => (
                text === 'Active' ? <div className={'tableStatus activeStatus'}>Active</div>
                    : text === 'Suspended' ? <div className={'tableStatus suspended'}>Suspended</div> :
                        <div className={'tableStatus debitOnHold'}>Debit on hold</div>
            ),
        },
    {
        title: 'Action',
            dataIndex
    :
        'action',
            key
    :
        'action',
            render: (text: any, record: any): any => (
                <Dropdown menu={{ items }} placement="bottomRight">
                    <button className={'actionButton'}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 2.66699C9 3.21928 8.55228 3.66699 8 3.66699C7.44772 3.66699 7 3.21928 7 2.66699C7 2.11471 7.44772 1.66699 8 1.66699C8.55228 1.66699 9 2.11471 9 2.66699Z" fill="black"/>
                            <path d="M9 8.00033C9 8.55261 8.55228 9.00033 8 9.00033C7.44772 9.00033 7 8.55261 7 8.00033C7 7.44804 7.44772 7.00033 8 7.00033C8.55228 7.00033 9 7.44804 9 8.00033Z" fill="black"/>
                            <path d="M8 14.3337C8.55228 14.3337 9 13.8859 9 13.3337C9 12.7814 8.55228 12.3337 8 12.3337C7.44772 12.3337 7 12.7814 7 13.3337C7 13.8859 7.44772 14.3337 8 14.3337Z" fill="black"/>
                        </svg>
                    </button>
                </Dropdown>
            ),
        },
    ];

    return (
        <div className={'accountDetails'}>
            <div className={'pageTitle'}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17.2871 23.7496C17.1004 23.7502 16.9158 23.709 16.7471 23.629C16.5784 23.549 16.4297 23.4321 16.3121 23.2871L10.2746 15.7871C10.0907 15.5634 9.99023 15.2829 9.99023 14.9934C9.99023 14.7038 10.0907 14.4233 10.2746 14.1996L16.5246 6.69961C16.7368 6.44434 17.0417 6.28381 17.3722 6.25334C17.7027 6.22286 18.0318 6.32494 18.2871 6.53711C18.5424 6.74929 18.7029 7.05417 18.7334 7.38471C18.7638 7.71524 18.6618 8.04434 18.4496 8.29961L12.8621 14.9996L18.2621 21.6996C18.4149 21.8831 18.512 22.1065 18.5419 22.3435C18.5717 22.5804 18.5331 22.8209 18.4305 23.0366C18.328 23.2522 18.1658 23.434 17.9631 23.5604C17.7605 23.6867 17.5259 23.7524 17.2871 23.7496Z"
                        fill="#050649"/>
                </svg>

                <div>Agent Details</div>
            </div>

            <div>
                <div className={'headingTitle'}>Basic Details</div>

                <div className={'boxDisplay'}>
                    <div className={'profileBox'}>
                        <img src={dummyHead} alt=""/>
                        <strong>Ogundipe Damilola Funmilayo</strong>
                        <div>
                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.80824 2.10545L5.8161 1.80188C6.27489 1.66346 6.76837 1.69674 7.20443 1.89549C7.64048 2.09425 7.9893 2.4449 8.18578 2.88198L8.81296 4.2772C8.98196 4.65306 9.02903 5.0724 8.94759 5.47637C8.86614 5.88035 8.66025 6.24868 8.35881 6.5297L7.26292 7.55152C7.24938 7.56417 7.23827 7.57919 7.23014 7.59584C7.11539 7.8302 7.28964 8.45616 7.83546 9.40209C8.4511 10.4682 8.92649 10.8896 9.14689 10.8246L10.5852 10.3844C10.9791 10.2642 11.4008 10.2701 11.7911 10.4016C12.1814 10.533 12.5209 10.7832 12.7618 11.1173L13.6531 12.3516C13.9329 12.7392 14.0625 13.2151 14.0179 13.691C13.9732 14.167 13.7573 14.6105 13.4102 14.9392L12.6434 15.6648C12.3768 15.9174 12.053 16.1017 11.6996 16.202C11.3463 16.3023 10.974 16.3157 10.6143 16.2409C8.47903 15.7965 6.56592 14.0771 4.85864 11.1203C3.15074 8.1617 2.61828 5.64205 3.30435 3.56988C3.41919 3.22296 3.61586 2.90878 3.87772 2.65389C4.13959 2.399 4.45835 2.21089 4.80824 2.10545ZM5.07174 2.9773C4.8618 3.04054 4.67016 3.15338 4.51302 3.30629C4.35588 3.45921 4.23786 3.6477 4.16892 3.85584C3.57756 5.64145 4.0566 7.90973 5.64731 10.6649C7.23681 13.4183 8.95989 14.9666 10.8001 15.3491C11.0159 15.3938 11.2393 15.3857 11.4513 15.3255C11.6633 15.2652 11.8575 15.1546 12.0175 15.003L12.7837 14.2781C12.9706 14.1011 13.087 13.8623 13.1111 13.606C13.1352 13.3497 13.0655 13.0934 12.9148 12.8847L12.0235 11.6497C11.8938 11.4699 11.7111 11.3352 11.501 11.2644C11.2908 11.1936 11.0638 11.1904 10.8517 11.2551L9.40978 11.6965C8.61199 11.9339 7.86156 11.2691 7.04678 9.85684C6.35646 8.66198 6.11724 7.79863 6.41231 7.19573C6.46939 7.07916 6.5471 6.97413 6.64181 6.88548L7.73771 5.86366C7.90008 5.71235 8.01099 5.51399 8.05488 5.29642C8.09876 5.07886 8.07341 4.85301 7.98239 4.65059L7.35521 3.25598C7.24942 3.02057 7.06155 2.83171 6.8267 2.72468C6.59185 2.61764 6.32607 2.59975 6.07899 2.67434L5.07114 2.97791L5.07174 2.9773Z"
                                    fill="#1F2937"/>
                            </svg>

                            <span>08060416760</span>
                        </div>
                        <div>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.54199 3.54199H12.7503C13.3139 3.54199 13.8544 3.76588 14.2529 4.16439C14.6514 4.56291 14.8753 5.10341 14.8753 5.66699V12.042C14.8753 12.6056 14.6514 13.1461 14.2529 13.5446C13.8544 13.9431 13.3139 14.167 12.7503 14.167H3.54199C2.97841 14.167 2.43791 13.9431 2.03939 13.5446C1.64088 13.1461 1.41699 12.6056 1.41699 12.042V5.66699C1.41699 5.10341 1.64088 4.56291 2.03939 4.16439C2.43791 3.76588 2.97841 3.54199 3.54199 3.54199ZM3.54199 4.25033C3.18783 4.25033 2.87616 4.37074 2.63533 4.58324L8.14616 8.14616L13.657 4.58324C13.4162 4.37074 13.1045 4.25033 12.7503 4.25033H3.54199ZM8.14616 9.00324L2.21741 5.15699C2.16074 5.31283 2.12533 5.48991 2.12533 5.66699V12.042C2.12533 12.4177 2.27458 12.778 2.54026 13.0437C2.80593 13.3094 3.16627 13.4587 3.54199 13.4587H12.7503C13.126 13.4587 13.4864 13.3094 13.7521 13.0437C14.0177 12.778 14.167 12.4177 14.167 12.042V5.66699C14.167 5.48991 14.1316 5.31283 14.0749 5.15699L8.14616 9.00324Z"
                                    fill="#1F2937"/>
                            </svg>

                            <span>ogundipe.funmi@gmail.com</span>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div>
                                <span>Gender</span>
                                <strong>Female</strong>
                            </div>

                            <div>
                                <span>Date of Birth</span>
                                <strong>Feb 4, 2023</strong>
                            </div>
                        </div>

                        <div>
                            <div>
                                <span>Residential Address</span>
                                <strong>Apt V6, Valley Estate, Lekki, Lagos</strong>
                            </div>
                            <div>
                                <span>State of Residence</span>
                                <strong>Lagos</strong>
                            </div>
                            <div>
                                <span>LGA</span>
                                <strong>Ikorodu</strong>
                            </div>
                        </div>

                        <div>
                            <div>
                                <span>Business Name</span>
                                <strong>Fortune Ventures</strong>
                            </div>
                            <div>
                                <span>Business Address</span>
                                <strong>Apt V6, Valley Estate, Lekki, Lagos</strong>
                            </div>
                            <div>
                                <span>Nearest Bus stop</span>
                                <strong>Shoprite, Lekki</strong>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className={'headingTitle'}>Account Details</div>

                <div>
                    <Table dataSource={dataSource} columns={columns} locale={{ emptyText: EmptyState }}/>;

                    <Modal
                        open={showSuspendAccount}
                        footer={null}
                        onCancel={() => setSuspendAccount(false)}
                    >
                        <div className={'invitationSent'}>
                            <div>
                                <div className={'invitationSentTitle'}>Invitation Sent</div>
                                <div className={'invitationSentMessage'}>Invites has been sent to the each agent’s email to complete their account setup</div>
                            </div>

                            <div>
                                <Button onClick={() => navigate('/dashboard')} style={{width: '350px'}} type="primary" htmlType="submit">
                                    Done
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
};
