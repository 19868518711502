import { maestroLight } from "assets/images";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate,Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { checkIsHome } from "utils";

const LandingHeader = () => {
  const [sidebar, setSidebar] = useState(false);
  const [navText, setNavText] = useState<string>("");
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isHome = checkIsHome(pathname, window.location.hash);

  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;

      if (window.pageYOffset > 80) {
        setNavText("navbar-fixed");
      } else {
        setNavText("");
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setSidebar(!sidebar);
  };

  const linkisActive = (to: string) => {
    return window.location.hash === to;
  };

  return (
    <div className="bg-foundation-blue-200 px-10">
      <nav className={`nav ${navText} bg-foundation-blue-200 `} id="nav">
        <div className="nav-center">
          {/* Nav header */}
          <div className="nav-header">
            <div>
              <img src={maestroLight} className="nav-logo" alt="" />
            </div>
            <div>
              <button
                id="nav-btn"
                className={`hamburger-button ${menuOpen ? "open" : ""}`}
                onClick={toggleMenu}
              >
                <div className="bar bg-white"></div>
                <div className="bar bg-white"></div>
                <div className="bar bg-white"></div>
              </button>
            </div>
          </div>

          <>
            <div className="nav-link-container">
              <div className="nav-links">
                <Link to={"/"}>
                  <span
                    className={`${
                      pathname === "/" ? "text-blue-300" : "text-white"
                    }`}
                  >
                    Home
                  </span>
                </Link>

                <HashLink to={"/agent-banking"}>
                  <span
                    className={`
                       "text-white"
                      `}
                  >
                    Become an agent
                  </span>
                </HashLink>

                <HashLink to={"/about"}>
                  <span
                    className={`${
                      pathname === "/about" ? "text-blue-300" : "text-white"
                    }`}
                  >
                    About
                  </span>
                </HashLink>

                <HashLink to={"#faqs"}>
                  <span
                    className={`${
                      linkisActive("#faqs") ? "text-blue-300" : "text-white"
                    }`}
                  >
                    FAQs
                  </span>
                </HashLink>

                <HashLink to={"#contact"}>
                  <span
                    className={`${
                      linkisActive("#contact") ? "text-blue-300" : "text-white"
                    }`}
                  >
                    Contact Us
                  </span>
                </HashLink>
              </div>
            </div>
          </>
        </div>
        {/* siderbar */}
        <aside
          className={`sidebar ${
            sidebar ? "show-sidebar" : ""
          } bg-foundation-blue-200`}
          id="sidebar"
        >
          <div>
            <div className="d-flex justify-content-end">
              <button
                id="nav-btn"
                className={`hamburger-button open`}
                onClick={toggleMenu}
              >
                <div className="bar bg-white"></div>
                <div className="bar bg-white"></div>
                <div className="bar bg-white"></div>
              </button>
            </div>
            <ul className="sidebar-menu">
              <li onClick={() => toggleMenu()}>
                <HashLink to={"/"}>Home</HashLink>
              </li>

              <li onClick={() => toggleMenu()}>
                <HashLink to={"/agent-banking"}>Become an agent</HashLink>
              </li>

              <li onClick={() => toggleMenu()}>
                <HashLink to={"/about"}>About</HashLink>
              </li>

              <li onClick={() => toggleMenu()}>
                <HashLink to={"/#faqs"}>Faqs</HashLink>
              </li>

              <li onClick={() => toggleMenu()}>
                <HashLink to={"/#contact"}>Contact Us</HashLink>
              </li>
            </ul>
          </div>
        </aside>
      </nav>
    </div>
  );
};

export { LandingHeader };
