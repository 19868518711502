import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import Moment from "moment";
import { fullMaestroLogoBlack } from "../../assets/images";
import {
  amountToWords,
  formatAmount,
  formatAmountNoCurrency,
} from "../../utils";
import {
  BeVietnamPro_Black,
  BeVietnamPro_Bold,
  BeVietnamPro_Medium,
  BeVietnamPro_Regular,
  BeVietnamPro_SemiBold,
} from "../../assets/fonts";

const fonts = {
  beVietnamPro: {
    regular: BeVietnamPro_Regular,
    medium: BeVietnamPro_Medium,
    semiBold: BeVietnamPro_SemiBold,
    bold: BeVietnamPro_Bold,
    heavy: BeVietnamPro_Black,
    // Add more font weights if necessary
  },
};

// Register the BeVietnamPro font family
Font.register({
  family: "BeVietnamPro",
  fonts: [
    { src: fonts.beVietnamPro.regular, fontWeight: "normal" },
    { src: fonts.beVietnamPro.medium, fontWeight: "medium" },
    { src: fonts.beVietnamPro.semiBold, fontWeight: "semibold" },
    { src: fonts.beVietnamPro.bold, fontWeight: "bold" },
    { src: fonts.beVietnamPro.heavy, fontWeight: "heavy" },
  ],
});

const styles = StyleSheet.create({
  body: {
    padding: 40,
    flex: 1,
  },
  mainHeader: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  mainHeaderText: {
    fontSize: 24,
    fontFamily: "BeVietnamPro",
    fontWeight: "normal",
    color: "#050649",
    //lineHeight: 28.8,
  },
  image: {
    height: 20,
    width: 127.216,
  },
  amountContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 30,
  },
  amountText: {
    fontSize: 34,
    color: "#3B4EE4",
    fontWeight: "normal",
    fontFamily: "BeVietnamPro",
    letterSpacing: -0.51,
    //lineHeight: 41,
  },
  amountInWords: {
    fontSize: 15,
    color: "#4B5563",
    fontWeight: "normal",
    fontFamily: "BeVietnamPro",
    letterSpacing: 0.015,
    //lineHeight: 20,
  },
  date: {
    color: "#6B7280",
    fontSize: 12,
    fontFamily: "BeVietnamPro",
    fontWeight: "normal",
    textAlign: "right",
  },
  rowTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 10,
  },
  sectionTitle: {
    color: "#111827",
    fontSize: 15,
    fontFamily: "BeVietnamPro",
    fontWeight: "normal",
    minWidth: 25,
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    //lineHeight: 20,
    letterSpacing: 0.015,
  },
  lineStyle: {
    borderWidth: 0.5,
    borderColor: "#111827",
    marginBottom: 10,
  },
  rowTitle: {
    color: "#6B7280",
    fontSize: 15,
    fontFamily: "BeVietnamPro",
    fontWeight: "normal",
    minWidth: 25,
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    //lineHeight: 20,
    letterSpacing: 0.015,
  },
  rowValue: {
    color: "#1F2937",
    fontSize: 15,
    fontFamily: "BeVietnamPro",
    fontWeight: "normal",
    minWidth: 35,
    textAlign: "left",
    maxWidth: "60%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    //lineHeight: 20,
    letterSpacing: 0.015,
  },

  bottomContainer: {
    // position: "absolute",
    // bottom: 10,
    // width: "100%",
    // paddingHorizontal: 40,
    marginTop: 30,
    width: 300,
    alignSelf: "center",
  },

  supportEmail: {
    color: "#3DADFE",
    fontSize: 12,
    fontFamily: "BeVietnamPro",
    fontWeight: "normal",
    textAlign: "center",
    //lineHeight: 14.4,
  },
  supportText: {
    color: "#9CA3AF",
    fontSize: 14,
    fontFamily: "BeVietnamPro",
    fontWeight: "normal",
    textAlign: "center",
    marginVertical: "1.5%",
    //lineHeight: 16.8,
  },

  endLine: {
    borderWidth: 0.5,
    borderColor: "#9CA3AF",
    marginTop: 10,
    borderStyle: "dashed",
  },
});

export const Receipt = (recieptData: any) => {
  //   const recieptDataDt = {
  //     amount: 0,
  //     channel: "string",
  //     currency: "string",
  //     destinationAccount: "string",
  //     destinationAccountBankCode: "string",
  //     destinationAccountName: "string",
  //     destinationNarration: "string",
  //     id: 0,
  //     paymenttransID: 0,
  //     sourceAccount: "string",
  //     sourceAccountBankCode: "string",
  //     sourceAccountName: "string",
  //     sourceNarration: "string",
  //     transactionDate: "2024-01-04T15:33:24.275Z",
  //     transactionOwnerId: 0,
  //     transactionOwnerPhoneNumber: "string",
  //     transactionRef: "string",
  //     transactionType: "BANK_ACCOUNT_TRANSFER",
  //   };

  return (
    <Document>
      <Page size="A4" wrap={false}>
        <View style={styles.body}>
          <View style={{ flex: 1 }}>
            <View style={styles.mainHeader}>
              <View>
                <Image style={styles.image} src={fullMaestroLogoBlack} />
              </View>
              <View>
                <Text style={styles.mainHeaderText}>Transaction Receipt</Text>
              </View>
            </View>

            <View style={styles.amountContainer}>
              <Text style={styles.amountText}>
                N
                {formatAmountNoCurrency(
                  recieptData?.amount ? recieptData?.amount : 0
                )}
              </Text>
              <Text style={styles.amountInWords}>
                {amountToWords(recieptData?.amount ? recieptData?.amount : 0)}{" "}
                naira
              </Text>
              <Text style={styles.date}>
                {Moment(
                  recieptData?.transactionDate
                    ? recieptData?.transactionDate
                    : new Date()
                ).format("MMMM Do YYYY, h:mm a")}
              </Text>
            </View>

            <View>
              {/* Sender Section */}
              <View>
                <View style={styles.rowTable}>
                  <Text style={styles.sectionTitle}>Sender</Text>
                </View>

                <View style={styles.lineStyle} />
              </View>

              <View style={styles.rowTable}>
                <Text style={styles.rowTitle}>Name</Text>
                <Text style={styles.rowValue}>
                  {recieptData?.sourceAccountName}
                </Text>
              </View>

              <View style={styles.rowTable}>
                <Text style={styles.rowTitle}>Account number</Text>
                <Text style={styles.rowValue}>
                  {recieptData?.sourceAccount}
                </Text>
              </View>

              <View style={styles.rowTable}>
                <Text style={styles.rowTitle}>Remark</Text>
                <Text style={styles.rowValue}>
                  {recieptData?.destinationNarration}
                </Text>
              </View>

              <View style={styles.rowTable}>
                <Text style={styles.rowTitle}>Reference number</Text>
                <Text style={styles.rowValue}>
                  {recieptData?.transactionRef}
                </Text>
              </View>

              {/* Beneficiary Section */}
              <View>
                <View style={styles.rowTable}>
                  <Text style={styles.sectionTitle}>Beneficiary</Text>
                </View>

                <View style={styles.lineStyle} />
              </View>

              <View style={styles.rowTable}>
                <Text style={styles.rowTitle}>Name</Text>
                <Text style={styles.rowValue}>
                  {recieptData?.destinationAccountName}
                </Text>
              </View>

              <View style={styles.rowTable}>
                <Text style={styles.rowTitle}>Receiving bank</Text>
                <Text style={styles.rowValue}>
                  {recieptData?.destinationAccountBankCode}
                </Text>
              </View>

              <View style={styles.rowTable}>
                <Text style={styles.rowTitle}>Account number</Text>
                <Text style={styles.rowValue}>
                  {recieptData?.destinationAccount}
                </Text>
              </View>
            </View>

            <View style={styles.bottomContainer}>
              <Text style={styles.supportText}>Support</Text>
              <Text style={styles.supportEmail}>
                customerservice@maestro.com
              </Text>

              <View style={styles.endLine} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
