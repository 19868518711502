import { Button, Form, Input, Modal, Select } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRequestInviteAgents } from "../../Auth/useAuth";
import { SubmitButton } from "../../../components";

export const AddIndividualAccount = () => {
  const [showInvitationSent, setInvitationSet] = useState(false);

  const { data, error, mutate, isLoading } = useRequestInviteAgents();

  var superAgenData: any = localStorage.getItem("authData");

  superAgenData = JSON.parse(superAgenData);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const onFinish = (data: any) => {
    console.log(data);

    mutate(
      {
        invitees: [
          {
            email: data?.emailAddress,
            message: "string",
            name:
              data?.firstName + " " + data?.surname + " " + data?.otherNames,
          },
        ],
        superAgentPhoneNumber: superAgenData?.phoneNumber,
      },
      {
        onSuccess: async (res: any) => {
          // setStep(2);
          setInvitationSet(true);
        },
        onError: (res: any) => {
          console.log(res);
        },
        onSettled: (res: any) => {},
      }
    );
  };
  const onFinishFailed = () => {};

  return (
    <div className={"addIndividualAccount"}>
      <div>
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.2871 23.7496C17.1004 23.7502 16.9158 23.709 16.7471 23.629C16.5784 23.549 16.4297 23.4321 16.3121 23.2871L10.2746 15.7871C10.0907 15.5634 9.99023 15.2829 9.99023 14.9934C9.99023 14.7038 10.0907 14.4233 10.2746 14.1996L16.5246 6.69961C16.7368 6.44434 17.0417 6.28381 17.3722 6.25334C17.7027 6.22286 18.0318 6.32494 18.2871 6.53711C18.5424 6.74929 18.7029 7.05417 18.7334 7.38471C18.7638 7.71524 18.6618 8.04434 18.4496 8.29961L12.8621 14.9996L18.2621 21.6996C18.4149 21.8831 18.512 22.1065 18.5419 22.3435C18.5717 22.5804 18.5331 22.8209 18.4305 23.0366C18.328 23.2522 18.1658 23.434 17.9631 23.5604C17.7605 23.6867 17.5259 23.7524 17.2871 23.7496Z"
            fill="#050649"
          />
        </svg>

        <div>Create Account</div>
      </div>

      <div>
        <div>Personal Information</div>

        <div>
          <Form
            className={"mestroForm"}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Title is required" }]}
            >
              <Select>
                <Select.Option value="mr">Mr.</Select.Option>
                <Select.Option value="ms">Ms.</Select.Option>
                <Select.Option value="mrs">Mrs.</Select.Option>
                <Select.Option value="dr">Dr.</Select.Option>
                <Select.Option value="prof">Prof.</Select.Option>
                <Select.Option value="other">Other</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Mobile number"
              name="mobileNumber"
              rules={[
                { required: true, message: "Mobile number is Required" },
                {
                  pattern: /^(\+234|0)[789]\d{9}$/,
                  message: "Please enter a valid Nigerian mobile number.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Surname"
              name="surname"
              rules={[{ required: true, message: "Surname is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="First name"
              name="firstName"
              rules={[{ required: true, message: "First name is Required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Other names" name="otherNames">
              <Input />
            </Form.Item>

            <Form.Item
              label="Email address"
              name="emailAddress"
              rules={[
                { required: true, message: "Email address is Required" },
                { type: "email" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item className={"singleColumn"}>
              <SubmitButton
                form={form}
                submitProp={<span> Send Invite</span>}
              />
            </Form.Item>
          </Form>

          <Modal
            open={showInvitationSent}
            footer={null}
            onCancel={() => setInvitationSet(false)}
          >
            <div className={"invitationSent"}>
              <div>
                <div className={"invitationSentTitle"}>Invitation Sent</div>
                <div className={"invitationSentMessage"}>
                  An invite has been sent to the agent’s email to complete
                  his/her account setup
                </div>
              </div>

              <div>
                <Button
                  onClick={() => navigate("/dashboard")}
                  style={{ width: "350px" }}
                  type="primary"
                  htmlType="submit"
                >
                  Done
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};
