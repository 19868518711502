import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
//import { getStoredUser } from "../../pages/auth/user-storage";

const { SubMenu } = Menu;
interface SuperAgentSideBarProps {
  dashboardSidebar: Boolean;
  showMenu: () => void;
}

const hasRoleOAGFAdmin = (roles: any) => {
  return roles?.some((role: { id: string }) => role?.id === "ROLE_OAGF_ADMIN");
};

const SuperAgentSideBar: React.FC<SuperAgentSideBarProps> = ({
  dashboardSidebar,
  showMenu,
}) => {
  const [activeMenu, setActiveMenu] = useState("");

  //const authed = getStoredUser();

  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname;

  const getParentMenuKey = (path: string) => {
    if (path.startsWith("/dashboard")) {
      return "dashboard";
    } else {
      return "dashboard"; // default parent menu key
    }
  };

  // Determine the parent menu key for the current route
  const currentParentMenuKey = getParentMenuKey(currentPath);

  // Set the active key and default selected keys
  const [activeKey, setActiveKey] = useState<string>(currentPath);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([
    currentParentMenuKey,
  ]);

  const handleClick = (e: any) => {
    if (e.length !== 0) {
      setActiveKey(e);
    }
  };

  useEffect(() => {
    // Set the default selected keys based on the parent menu key
    setDefaultSelectedKeys([currentParentMenuKey]);
  }, [currentParentMenuKey]);

  const closeSidebar = () => {
    if (dashboardSidebar) {
      showMenu();
    }
  };

  return (
    <div
      className={` sidebarMain ${
        dashboardSidebar ? " sidebarMainActivated" : ""
      }`}
    >
      <nav>
        <Menu
          mode="inline"
          // style={generalStyle}
          // defaultOpenKeys={[activeKey]}
          onOpenChange={handleClick}
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={[currentParentMenuKey]}
          className="sidebarHeading"
        >
          <Menu.Item
            key="/dashboard"
            className={`menu-item
              ${currentPath === "/dashboard" ? "text-primary" : ""}
            `}
          >
            <Link className="ps-0" onClick={closeSidebar} to="/dashboard">
              Overview
            </Link>
          </Menu.Item>

          <SubMenu
            key="services"
            title="Services"
            className={`subMenu
              ${
                currentPath === "/admin/setup-administration/"
                  ? "text-primary"
                  : ""
              }
            `}
          >
            <Menu.Item
              key="/services/1"
              className={`subMenu-item
              ${currentPath === "/services" ? "text-primary" : ""}
            `}
            >
              <Link className="ps-0" onClick={closeSidebar} to="/services/1">
                Services
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/services/2"
              className={`subMenu-item
               ${currentPath === "/services" ? "text-primary" : ""}
             `}
            >
              <Link className="ps-0" onClick={closeSidebar} to="/services/2">
                Services
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/services/3"
              className={`subMenu-item
               ${currentPath === "/services" ? "text-primary" : ""}
             `}
            >
              <Link className="ps-0" onClick={closeSidebar} to="/services/3">
                Services
              </Link>
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            key="/transactions"
            className={`menu-item 
              ${currentPath === "/transactions" ? "text-primary" : ""}
            `}
          >
            <Link className="ps-0" onClick={closeSidebar} to="/transactions">
              Transaction History
            </Link>
          </Menu.Item>
        </Menu>
      </nav>

      {/* <aside
        className={`auth-layout-header  sidebar ${
          dashboardSidebar ? "show-sidebar" : ""
        }`}
        id="sidebar"
      >
        <div>
          <>
            <div className="d-flex justify-content-end">
              <button
                id="nav-btn"
                className={`hamburger-button open`}
                onClick={showMenu}
              >
                <div className="bar bg-white"></div>
                <div className="bar bg-white"></div>
                <div className="bar bg-white"></div>
              </button>
            </div>
            <nav>
              <Menu
                mode="inline"
                // style={generalStyle}
                // defaultOpenKeys={[activeKey]}
                onOpenChange={handleClick}
                defaultSelectedKeys={defaultSelectedKeys}
                defaultOpenKeys={[currentParentMenuKey]}
                className="sidebarHeading"
              >
                <Menu.Item
                  key="/dashboard"
                  className={`menu-item
              ${currentPath === "/dashboard" ? "text-primary" : ""}
            `}
                >
                  <Link className="ps-0" onClick={closeSidebar} to="/dashboard">
                    Overview
                  </Link>
                </Menu.Item>

                <SubMenu
                  key="services"
                  title="Services"
                  className={`subMenu
              ${
                currentPath === "/admin/setup-administration/"
                  ? "text-primary"
                  : ""
              }
            `}
                >
                  <Menu.Item
                    key="/services/1"
                    className={`subMenu-item
              ${currentPath === "/services" ? "text-primary" : ""}
            `}
                  >
                    <Link className="ps-0" onClick={closeSidebar} to="/services/1">
                      Services
                    </Link>
                  </Menu.Item>

                  <Menu.Item
                    key="/services/2"
                    className={`subMenu-item
               ${currentPath === "/services" ? "text-primary" : ""}
             `}
                  >
                    <Link className="ps-0" onClick={closeSidebar} to="/services/2">
                      Services
                    </Link>
                  </Menu.Item>

                  <Menu.Item
                    key="/services/3"
                    className={`subMenu-item
               ${currentPath === "/services" ? "text-primary" : ""}
             `}
                  >
                    <Link className="ps-0" onClick={closeSidebar} to="/services/3">
                      Services
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <Menu.Item
                  key="/transactions"
                  className={`menu-item 
              ${currentPath === "/transactions" ? "text-primary" : ""}
            `}
                >
                  <Link className="ps-0" onClick={closeSidebar} to="/transactions">
                    Transaction History
                  </Link>
                </Menu.Item>
              </Menu>
            </nav>
          </>
        </div>
      </aside> */}
    </div>
  );
};

export default SuperAgentSideBar;
