import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { ErrorHandler, ErrorHandlerFn } from "../services/ErrorHandler";
import { axiosInstance } from "../services/constants/AxiosInstance";

export function useGetBillers(): any {
  return useMutation(
    async (data: any) => {
      return await axiosInstance.get(`/api/itex/purchase/vtu`);
    },
    {
      onSuccess: (res) => {
        // SuccessToast("Your request was successful");
      },
      onError: (err: any) => {
        ErrorHandler(err);
      },
    }
  );
}

// export function useGetDataPlans(): any {
//   return useMutation(
//     async (data: any) => {
//       return await axiosInstance.post(`/api/itex/lookup/data`, {
//         service: data,
//       });
//     },
//     {
//       onSuccess: (res) => {
//         // SuccessToast("Your request was successful");
//       },
//       onError: (err: any) => {
//         ErrorHandler(err);
//       },
//     }
//   );
// }

export function useGetDataPlans(): any {
  async function getDataPlans(billerData: any): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/itex/lookup/data`,
      { service: billerData }
    );
    return data.data.data;
  }

  return useMutation((values: any) => getDataPlans(values), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandlerFn(err);
    },
  });
}

// export function useBuyDataPlans(): any {
//   return useMutation(
//     async (data: any) => {
//       //`${URL2}/itex/subscribe/data`
//       return await axiosInstance.post(`/api/itex/subscribe/data`);
//     },
//     {
//       onSuccess: (res) => {
//         // SuccessToast("Your request was successful");
//       },
//       onError: (err: any) => {
//         ErrorHandler(err);
//       },
//     }
//   );
// }

export function useBuyDataPlans(): any {
  async function buyData(values: any): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/itex/subscribe/data`,

      values
    );
    return data.data;
  }

  return useMutation((values: any) => buyData(values), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandlerFn(err);
    },
  });
}

export function useBuyAirtime(): any {
  return useMutation(
    async (data: any) => {
      return await axiosInstance.post(`/api/itex/internet/bundles`);
    },
    {
      onSuccess: (res) => {
        // SuccessToast("Your request was successful");
      },
      onError: (err: any) => {
        ErrorHandler(err);
      },
    }
  );
}

export function useRequestBuyAirtime(): any {
  async function buyAirtime(values: any): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/itex/purchase/vtu`,

      values
    );
    return data.data;
  }

  return useMutation((values: any) => buyAirtime(values), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandlerFn(err);
    },
  });
}
