import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import AgentSideBar from "./component/authenticated/AgentSideBar";
import { AuthHeader } from "./component/authenticated/AuthHeader";
type Props = {
  children?: any;
};

const AgentLayout: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  const [dashboardSidebar, setDashboardSidebar] = useState<boolean>(false);

  function showMenu() {
    setDashboardSidebar(!dashboardSidebar);
  }

  return (
    <div className="mainDashboard">
      <header>
        <AuthHeader showMenu={showMenu} dashboardSidebar={dashboardSidebar} />
      </header>

      <section className={"sideBar-Outlet"}>
        <div className={"dashboardSidebar"}>
          <AgentSideBar
            dashboardSidebar={dashboardSidebar}
            showMenu={showMenu}
          />
        </div>
        <div className="main-container">
          <div className="content">
            <Outlet />
          </div>
        </div>
      </section>
    </div>
  );
};

export { AgentLayout };
