export const getLocalData = (key: string) => {
  let data: any = localStorage.getItem(key);
  data = JSON.parse(data);
  return data;
};

export const getToken = (): string => {
  let data: any = localStorage.getItem("token") as string;
  return data;
};
export const getLoggedUser = (): string => {
  let data: any = localStorage.getItem("authData") as string;
  data = JSON.parse(data);
  return data.profileType.profiletype;
};

export const userIsSuperAgent = (): boolean => {
  let data: any = localStorage.getItem("authData") as string;
  data = JSON.parse(data);
  return data.profileType.profiletype === "Super_agent";
};

export function clearStoredUser(): void {
  localStorage.clear();
}
