import React, { Key, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import './PayTV.scss';
import {
  useBuyAirtime,
  useBuyDataPlans,
  useGetBillers,
  useGetDataPlans,
} from '../../../apis/overview';
import { Breadcrumb, PinOverlay, SuccessMessage } from '../../../components';
import { useRequestCustomerWalletAccounts } from '../../Auth/useAuth';
import { formatAmount } from '../../../utils';

export const PayTV = () => {
  const [showModal, setShowModal] = useState(false);
  const [showPin, setShowPin] = React.useState<boolean>(false);
  const [pin, setPin] = React.useState<string>('');
  const [selectedAccount, setSelectedAccount] = useState<any>([]);

  const tabs = ['Airtime', 'Data'];
  const links = [
    {
      name: 'Overview',
      path: '/dashboard',
    },
    {
      name: 'PayTv',
    },
  ];

  const { data, mutate, isLoading } = useGetBillers();
  const {
    data: getDataPlans,
    mutate: mutateDataPlans,
    isLoading: loadDataplans,
  } = useGetDataPlans();
  const {
    data: buyDataPlans,
    mutate: mutateBuyDataPlans,
    isLoading: loadBuyDataplans,
  } = useBuyDataPlans();
  const {
    data: buyAirtime,
    mutate: mutateBuyAirtime,
    isLoading: loadBuyAirtime,
  } = useBuyAirtime();

  const [airtimeForm] = Form.useForm();



  const {
    data: acctList,
    mutate: getAcctList,
    isLoading: acctLoading,
  } = useRequestCustomerWalletAccounts();

  const [maxAmount, setMaxAmount] = useState<number>(0);

  const onSendFormSelect = (item: any) => {
    var itemA = JSON.parse(item);

    setSelectedAccount(JSON.parse(item));

    setMaxAmount(itemA?.currentBalance);
  };

  const proceed = () => {
    setShowPin(false);

    setShowModal(true);
  };
    
      const payment = (values: any) => {
    console.log(values);
    setShowPin(true);
  };

  useEffect(() => {
    mutate();
    getAcctList();
    // console.log('WWWWW: ', data)
  }, []);

  return (
    <div className={'payTv'}>
      <div>
        <Breadcrumb links={links} />
      </div>
      <div className={'payTvMain'}>
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="50" height="50" rx="4" fill="#E6EBF2" />
          <path
            d="M16.7148 32.5V21.5C16.7148 20.9696 16.8955 20.4609 17.2169 20.0858C17.5384 19.7107 17.9745 19.5 18.4291 19.5H32.1434C32.5981 19.5 33.0341 19.7107 33.3556 20.0858C33.6771 20.4609 33.8577 20.9696 33.8577 21.5V32.5C33.8577 33.0304 33.6771 33.5391 33.3556 33.9142C33.0341 34.2893 32.5981 34.5 32.1434 34.5H18.4291C17.9745 34.5 17.5384 34.2893 17.2169 33.9142C16.8955 33.5391 16.7148 33.0304 16.7148 32.5Z"
            stroke="#33588F"
            stroke-width="1.5"
          />
          <path
            d="M22.2861 15L25.2861 18.5L28.2861 15"
            stroke="#33588F"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <Form form={airtimeForm} layout="vertical" onFinish={payment}>
          <Form.Item
            label="Account"
            name={'sendFrom'}
            rules={[
              {
                required: true,
                message: 'Select Account to send from',
              },
            ]}
          >
            <Select onChange={onSendFormSelect}>
              <Select.Option value="">
                <div className="d-flex justify-content-between align-items-center py-1">
                  <h1 className="text-primary fs-9 fw-bold"></h1>
                </div>
              </Select.Option>
              {acctList?.map((item: any, index: Key | null | undefined) => {
                return (
                  <Select.Option value={JSON.stringify(item)} key={index}>
                    <div className="d-flex justify-content-between align-items-center py-1">
                      <div className="d-flex flex-column">
                        <h1 className="text-gray-800 fs-9 fw-bold">
                          {item?.accountFullName}
                        </h1>
                        <h1 className="text-gray-800 fs-9 fw-bold">
                          {item.accountNumber}
                        </h1>
                      </div>
                      <div>
                        <h1 className="text-primary fs-9 fw-bold">
                          {formatAmount(item.currentBalance || 0)}
                        </h1>
                      </div>
                    </div>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Service Provider"
            name="provider"
            rules={[{ required: true, message: 'Select a tv provider' }]}
          >
            <Select onChange={() => mutateDataPlans()}>
              <Select.Option value="mtn">DSTV</Select.Option>
              <Select.Option value="glo">GoTv</Select.Option>
              <Select.Option value="airtel">Startimes</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="TV Bankuet"
            name="plans"
            rules={[{ required: true, message: 'Select a tv provider' }]}
          >
            <Select onChange={() => mutateDataPlans()}>
              <Select.Option value="mtn">DSTV Padi - N1000</Select.Option>
              <Select.Option value="glo">DSTV Yanga - N2000</Select.Option>
              <Select.Option value="airtel">DSTV Confam - N3000</Select.Option>
              <Select.Option value="123">DSTV Compact - N4000</Select.Option>
              <Select.Option value="456">DSTV Plus - N5000</Select.Option>
              <Select.Option value="987">DSTV Premium - N6000</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button style={{ width: '100%' }} type="primary" htmlType="submit">
              Pay
            </Button>
          </Form.Item>
        </Form>
          </div>
          
              <PinOverlay
        showModal={showPin}
        setShowModal={(e: boolean) => setShowPin(e)}
        setPin={setPin}
        pin={pin}
        proceed={proceed}
      />

      <Modal
        open={showModal}
        footer={false}
        centered={true}
        onCancel={() => setShowModal(false)}
        cancelButtonProps={{ className: 'd-none' }}
        className="pin-overlay"
        closeIcon={false}
      >
        <SuccessMessage closeModal={(x: boolean) => setShowModal(x)} />
      </Modal>
    </div>
  );
};
