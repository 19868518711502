import { maestroGif } from "../../assets/images";

export function FallbackView() {
  return (
    <div className="bg-white vh-100 d-flex justify-content-center align-items-center">
      {/* <span>Loading ...</span> */}
      <img src={maestroGif} width="600px" />
    </div>
  );
}
