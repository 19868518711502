import { Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { SubmitButton } from "components";
import "./Personaldatarights.scss";

const { Option } = Select;

export const Personaldatarights = () => {
  const onFinish = (values: any) => {};
  const onFinishFailed = (values: any) => {};

  const [form] = Form.useForm();

  return (
    <div className="personal-data-rights-container">
      <div className="header-section">
        <h1 className=" header-text fs-2qx">
          Customer Account Information Retrieval
        </h1>
        <p className="text-gray-500 ">
          As a valued customer of Maestro MFB, you can request specific account
          data, which we are bound to give you while adhering to strict security
          measures and ensuring compliance with financial regulations
        </p>
      </div>

      <div className="main-content-container">
        <p className="text-gray-500 text-center">
          Please fill out this form and we will reach out to you.
        </p>

        <div>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <div className="rights-input-grid">
              <Form.Item
                label="Account name"
                name="accountName"
                rules={[
                  { required: true, message: "Please input Account name!" },
                ]}
                className=""
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Account number"
                name="accountNumber"
                rules={[
                  { required: true, message: "Please input Account number!" },
                  {
                    min: 10,
                    max: 10,
                  },
                ]}
                className=""
              >
                <Input type="number" />
              </Form.Item>

              <Form.Item
                label="Email address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email address!",
                  },
                  {
                    type: "email",
                    message: "The input is not valid Email address!",
                  },
                ]}
                className=""
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Phone number"
                name="phone"
                className=""
                rules={[
                  { required: true, message: "Please input Phone number" },
                  {
                    min: 11,
                  },
                  {
                    pattern: /^(\+234|0)[789]\d{9}$/,
                    message: "Please enter a valid Nigerian mobile number.",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>

              <Form.Item
                label="Contact address"
                name="address"
                rules={[
                  { required: true, message: "Please input Contact address!" },
                ]}
                className=""
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={"requestType"}
                label={
                  <label className="text-federal-blue ">
                    What is your NDPR related request?
                  </label>
                }
                rules={[{ message: "Request Type is required" }]}
                className="rights-input-style"
              >
                <Select
                  popupMatchSelectWidth={false}
                  allowClear
                  placeholder=""
                  suffixIcon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 6L8 10L12 6"
                        stroke="#050649"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                >
                  <Option value="delete">
                    I want you to delete my personal data (“right to be
                    forgotten”)
                  </Option>
                  <Option value="transfer">
                    I want you to transfer my data to me or a third party
                  </Option>
                  <Option value="howMyDataIsUsed">
                    I want to know how you are using my personal information
                  </Option>
                  <Option value="stopMarketing">
                    I want you to stop using my information for direct marketing
                    and profiling
                  </Option>
                  <Option value="stopProcessing">
                    I want you to keep my data but to stop processing it
                  </Option>
                  <Option value="objectHowMyDataIsUsed">
                    I want to object the way you are using my personal
                    information
                  </Option>
                </Select>
              </Form.Item>
            </div>

            <Form.Item
              label="Please specify in detail your purpose of request"
              name="purpose"
              rules={[
                { required: true, message: "Please input purpose of request!" },
              ]}
              className=""
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Please provide a detailed description of the data requested and its content."
              name="description"
              rules={[{ required: true, message: "Please enter description!" }]}
              className=""
            >
              <TextArea />
            </Form.Item>

            <div className="note">
              <span className="text-gray-500">
                <span className="fw-bolder">N.B:</span> Regulation might require
                us to keep certain data for a minimum period after the end of
                the business relationship.
              </span>
            </div>

            <Form.Item className="d-flex justify-content-center">
              <SubmitButton form={form} submitProp={<span>Submit</span>} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
