import React, { ReactNode } from "react";
import { Button, Modal } from "antd";
import { SuccessMessage } from "./SuccessMessage";
import { DialogModal } from "./DialogModal";

interface PaySuccessModalProps {
  showModal: boolean;
  onClick: () => void;
  amount?: number;
  message?: ReactNode
  title?: string
}

export const PaySuccessModal: React.FC<PaySuccessModalProps> = ({
  showModal,
  amount,
  onClick,
  message,
  title
}) => {
  return (
    <DialogModal showModal={showModal} setShowModal={onClick}>
      <SuccessMessage message={message} amount={amount} onClick={onClick} title={title}/>
    </DialogModal>
  );
};
