import { Modal, Button, Form, Input } from "antd";
import OTPInput from "react-otp-input";
import React, { useEffect, useState } from "react";
import { CustomButton } from "./FormSubmitBtn";

interface PinOverlayProps {
  showModal: boolean;
  setShowModal: (e: boolean) => void;
  children?: React.ReactNode;
  setPin: (e: string) => void;
  pin: string;
  proceed: () => void;
}

export const PinOverlay: React.FC<PinOverlayProps> = ({
  showModal,
  setShowModal,
  children,
  pin,
  setPin,
  proceed,
}) => {
  return (
    <Modal
      open={showModal}
      footer={false}
      centered={true}
      onCancel={() => setShowModal(false)}
      cancelButtonProps={{ className: "d-none" }}
      className="pin-overlay"
      closeIcon={false}
    >
      <div className="pin-overlay">
        <h1 className="text-gray-800 fs-8 fw-bold  pop px-6">
          Enter your 4-Digit PIN to authorise this transaction
        </h1>

        <div>
          <OTPInput
            value={pin}
            onChange={setPin}
            numInputs={4}
            placeholder="----"
            renderSeparator={<div className="pin-separator" />}
            renderInput={(props) => <input {...props} />}
            containerStyle={"otp-pin mt-2 d-flex justify-content-between"}
          />
        </div>

        <div className="mt-10 d-flex justify-content-center">
          <CustomButton
            disabled={pin.length < 4}
            onClick={proceed}
            submitProp={<span className="fs-base fw-bold text-white">Pay</span>}
          />
        </div>
      </div>
    </Modal>
  );
};
