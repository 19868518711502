import React, {Key, useEffect, useState} from "react";
import {Button, Form, Input, Modal, Select} from "antd";
import "./ElectricityBill.scss"
import {useBuyAirtime, useBuyDataPlans, useGetBillers, useGetDataPlans} from "../../../apis/overview";
import {Breadcrumb, PinOverlay, SuccessMessage} from "../../../components";
import { useRequestCustomerWalletAccounts } from "../../Auth/useAuth";
import { formatAmount } from "../../../utils";

export const ElectricityBill = () => {
    const [showModal, setShowModal] = useState(false)
      const [showPin, setShowPin] = React.useState<boolean>(false);
  const [pin, setPin] = React.useState<string>('');
  const [selectedAccount, setSelectedAccount] = useState<any>([]);

    const tabs = ['Airtime', 'Data'];
    const links = [
        {
            name: 'Overview',
            path: '/dashboard'
        },
        {
            name: 'Electricity Bill',
        }
    ]

    const { data, mutate, isLoading } = useGetBillers();
    const { data: getDataPlans, mutate: mutateDataPlans, isLoading: loadDataplans } = useGetDataPlans();
    const { data: buyDataPlans, mutate: mutateBuyDataPlans, isLoading: loadBuyDataplans } = useBuyDataPlans();
    const { data: buyAirtime, mutate: mutateBuyAirtime, isLoading: loadBuyAirtime } = useBuyAirtime();

      const {
    data: acctList,
    mutate: getAcctList,
    isLoading: acctLoading,
      } = useRequestCustomerWalletAccounts();
    
      const [maxAmount, setMaxAmount] = useState<number>(0);

  const onSendFormSelect = (item: any) => {
    var itemA = JSON.parse(item);

    setSelectedAccount(JSON.parse(item));

    setMaxAmount(itemA?.currentBalance);
  };
    
    const proceed = () => {
        setShowPin(false)


           setShowModal(true)
    }



    const [airtimeForm] = Form.useForm();

    const payment = (values: any) => {
        console.log(values);
        setShowPin(true)
    };

    useEffect(() => {
        mutate()
        getAcctList()
        // console.log('WWWWW: ', data)
    }, []);


    return (
        <div className={'electricityBill'}>
            <div>
                <Breadcrumb links={links}/>
            </div>
            <div className={'electricityBillMain'}>

                <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="50" height="47.152" rx="4" fill="#FEF9C3"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M28.1565 10C27.8236 10 27.5124 10.1657 27.3267 10.442L16.5693 26.442C16.1227 27.1063 16.5987 28 17.3992 28H23.599L21.7251 35.9168C21.4712 36.9895 22.8853 37.6237 23.5174 36.7206L33.4204 22.5735C33.8844 21.9107 33.4102 21 32.6012 21H26.9237L29.2313 11.2299C29.3796 10.6019 28.9033 10 28.2581 10H28.1565Z"
                          fill="#FFAE11"/>
                </svg>


                <Form form={airtimeForm} layout="vertical" onFinish={payment}>


                            <Form.Item
              label="Account"
              name={'sendFrom'}
              rules={[
                {
                  required: true,
                  message: 'Select Account to send from',
                },
              ]}
            >
              <Select onChange={onSendFormSelect}>
                <Select.Option value="">
                  <div className="d-flex justify-content-between align-items-center py-1">
                    <h1 className="text-primary fs-9 fw-bold"></h1>
                  </div>
                </Select.Option>
                {acctList?.map((item: any, index: Key | null | undefined) => {
                  return (
                    <Select.Option value={JSON.stringify(item)} key={index}>
                      <div className="d-flex justify-content-between align-items-center py-1">
                        <div className="d-flex flex-column">
                          <h1 className="text-gray-800 fs-9 fw-bold">
                            {item?.accountFullName}
                          </h1>
                          <h1 className="text-gray-800 fs-9 fw-bold">
                            {item.accountNumber}
                          </h1>
                        </div>
                        <div>
                          <h1 className="text-primary fs-9 fw-bold">
                            {formatAmount(item.currentBalance || 0)}
                          </h1>
                        </div>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

                    <Form.Item label="Power Provider" name="provider"
                               rules={[{required: true, message: 'Select a tv provider'}]}>
                        <Select onChange={() => mutateDataPlans()}>
                            <Select.Option value="mtn">EEDC</Select.Option>
                            <Select.Option value="glo">IKEDEC</Select.Option>
                            <Select.Option value="airtel">ILORIN Power</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Amount" name="plans" rules={[{required: true, message: 'Enter an Amount'}]}>
                        <Input />
                    </Form.Item>

                    <Form.Item>
                        <Button style={{width: '100%'}} type="primary" htmlType="submit">
                            Pay
                        </Button>
                    </Form.Item>
                </Form>
            </div>
    <PinOverlay
        showModal={showPin}
        setShowModal={(e: boolean) => setShowPin(e)}
        setPin={setPin}
        pin={pin}
        proceed={proceed}
      />
            <Modal
                open={showModal}
                footer={false}
                centered={true}
                onCancel={() => setShowModal(false)}
                cancelButtonProps={{className: "d-none"}}
                className="pin-overlay"
                closeIcon={false}
            >
                <SuccessMessage closeModal={(x: boolean) => setShowModal(x)}/>
            </Modal>
        </div>
    );
};
