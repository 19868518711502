import { AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// import type { User } from '../../../../../shared/types';
import { axiosInstance } from "../../services/constants/AxiosInstance";
import { SuccessToast } from "../../services/toast";
import { ErrorHandler, ErrorHandlerFn } from "../../services/ErrorHandler";
import { serviceName } from "../../services/constants/baseUrl";

async function requestSignin(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
    `/api/authenticate`,
    values
  );

  return data;
}

async function requestSignup(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
    `/api/register`,
    values
  );

  return data;
}

async function requestValidateBVN(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.get(
    `/api/verify-otp/${values.otp}/${values.phone}`
  );
  return data;
}

async function requestValidateOtp(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.get(
    `/api/verify-otp/${values.otp}/${values.phone}`
  );

  return data;
}

async function requestGetSuperAgentMetrics(): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.get(
    `/api/agents/dashboard`
  );

  return data;
}

async function requestGetSuperAgentAgents(value: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.get(
    `/api/agents/${value}/agent`
  );

  return data;
}

async function requestInviteAgents(value: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.get(
    `/api/agents/invite`
  );

  return data;
}

async function requestCustomerWalletAccounts(): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.get(
    `/api/customer_wallet-accounts`
  );

  return data;
}

async function requestGetUserTransactions(value: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.get(
    `/api/customer_debit_credit/last5`
  );

  return data;
}

async function requestGetTransaction(value: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.get(
    `/api/account_debit_credit/${value}`
  );

  return data;
}

async function requestFundWallet(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
    `/api/fund-wallet`,
    values
  );
  return data;
}

export function useRequestSignin(): any {
  return useMutation((value) => requestSignin(value), {
    onSuccess: (res) => {
      if (res?.code === "00") {
        SuccessToast("Your request was successful");
      } else {
        // console.log(res?.data?.message, "here now");

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      ErrorHandlerFn(err);
    },
  });
}

export function useRequestSignup(): any {
  return useMutation((value) => requestSignup(value), {
    onSuccess: (res) => {
      if (res?.code === "00") {
        SuccessToast("Your request was successful");
      } else {
        // console.log(res?.data?.message, "here now");

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.message);
    },
  });
}

export function useRequestValidateOtp(): any {
  return useMutation((value) => requestValidateOtp(value), {
    onSuccess: (res) => {
      if (res?.code === "00") {
        SuccessToast("Your request was successful");
      } else {
        // console.log(res?.data?.message, "here now");

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.message);
    },
  });
}

export function useRequestValidateBVN(): any {
  return useMutation((value) => requestValidateBVN(value), {
    onSuccess: (res) => {
      if (res?.code === "00") {
        SuccessToast("Your request was successful");
      } else {
        // console.log(res?.data?.message, "here now");

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.message);
    },
  });
}

export function useRequestGetSuperAgentMetrics(): any {
  return useMutation(() => requestGetSuperAgentMetrics(), {
    onSuccess: (res) => {
      if (res?.code === "00") {
        SuccessToast("Your request was successful");
      } else {
        // console.log(res?.data?.message, "here now");

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.message);
    },
  });
}

export function useRequestGetSuperAgentAgents(): any {
  return useMutation((value) => requestGetSuperAgentAgents(value), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandlerFn(err);
    },
  });
}

export function useRequestInviteAgents(): any {
  return useMutation((value) => requestInviteAgents(value), {
    onSuccess: (res) => {
      if (res?.code === "00") {
        SuccessToast("Your request was successful");
      } else {
        // console.log(res?.data?.message, "here now");

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.message);
    },
  });
}

export function useRequestCustomerWalletAccounts(): any {
  return useMutation(() => requestCustomerWalletAccounts(), {
    onSuccess: (res) => {
      if (res?.code === "00") {
        SuccessToast("Your request was successful");
      } else {
        // console.log(res?.data?.message, "here now");

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.message);
    },
  });
}

export function useRequestFundWallet(): any {
  return useMutation((value) => requestFundWallet(value), {
    onSuccess: (res) => {
      if (res?.code === "00") {
        SuccessToast("Your request was successful");
      } else {
        // console.log(res?.data?.message, "here now");

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.message);
    },
  });
}

export function useRequestGetUserTransactions(): any {
  return useMutation((value) => requestGetUserTransactions(value), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.message);
    },
  });
}
export function useGetHistory(): any {
  return useMutation((value) => requestGetTransaction(value), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.message);
    },
  });
}

export function useSendOtp(): any {
  async function sendOTP(phone: any): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance.get(
      `/api/send-otp/${phone}/${serviceName}`
    );
    return data;
  }

  return useMutation((phone) => sendOTP(phone), {
    onSuccess: (res) => {
      SuccessToast("OTP resent successfully");
    },
    onError: (err: any) => {
      ErrorHandlerFn(err);
    },
  });
}
