import AboutPage from "pages/LandingPage/AboutPage";
import PrivacyPolicy from "pages/LandingPage/PrivacyPolicy";
import TermsOfService from "pages/LandingPage/TermsOfService";
import { FC, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { AppLayout } from "../components/src/layout/AppLayout";
import { LandingLayout } from "../components/src/layout/LandingLayout/LandingLayout";
import { MainLayout } from "../components/src/layout/MainLayout";
import { AgentFundAccount } from "../pages/Services/AgentFundAccount/AgentFundAccount";
import { AirtimeAndData } from "../pages/Services/AirtimeAndData/AirtimeAndData";
import { ElectricityBill } from "../pages/Services/ElectricityBill/ElectricityBill";
import { PayTV } from "../pages/Services/PayTV/PayTV";
import { AddBulkAccount } from "../pages/SuperAgent/AccountCreation/AddBulkAccount";
import { AddIndividualAccount } from "../pages/SuperAgent/AccountCreation/AddIndividualAccount";
import { AccountDetails } from "../pages/SuperAgent/AccountDetails/AccountDetails";
import { FundAccount } from "../pages/SuperAgent/AccountDetails/FundAccount";
import { Error404 } from "./component/Error404";
import { FallbackView } from "./component/FallbackView";
import PrivateRoutes, {
  PrivateSuperAgent,
  UserDashboardPick,
  UserLayoutPick,
} from "./component/PrivateRoutes";
import { Personaldatarights } from "pages/LandingPage/Personaldatarights";

const AppRouter: FC = () => {
  const LandingPage = lazy(() => import("../pages/LandingPage"));
  const AgentPage = lazy(() => import("../pages/Agent"));
  const Login = lazy(() => import("../pages/Auth/Login"));
  const ResetPassword = lazy(() => import("../pages/Auth/ResetPassword"));
  const Registration = lazy(() => import("../pages/Auth/Registration"));
  const Dashboard = lazy(() => import("../pages/Dashboard"));
  const SendMoney = lazy(() => import("../pages/Services/SendMoney"));
  const RequestMoney = lazy(() => import("../pages/Services/RequestMoney"));
  const Profile = lazy(() => import("../pages/Profile"));
  const TransactionHistory = lazy(() => import("../pages/TransactionHistory"));
  const Checkout = lazy(() => import("../pages/UsefulPages/Checkout"));

  return (
    <Suspense fallback={<FallbackView />}>
      <Routes>
        <Route element={<AppLayout />}>
          <Route element={<MainLayout />}>
            <Route path="/agent-banking" element={<AgentPage />} />
          </Route>

          <Route element={<LandingLayout />}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route
              path="/personaldatarightsform"
              element={<Personaldatarights />}
            />
          </Route>

          <Route path="/signIn" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/checkout/:id" element={<Checkout />} />

          <Route path="/signUp" element={<Registration />} />

          <Route element={<PrivateRoutes />}>
            <Route element={<UserLayoutPick />}>
              <Route path="/dashboard" element={<UserDashboardPick />} />
              <Route path="/services/send-money" element={<SendMoney />} />
              <Route
                path="/services/receive-money"
                element={<RequestMoney />}
              />
              <Route
                path="/services/fund-account"
                element={<AgentFundAccount />}
              />
              <Route
                path="/services/airtime-data"
                element={<AirtimeAndData />}
              />
              <Route path="/services/pay-tv" element={<PayTV />} />
              <Route
                path="/services/electricity-bill"
                element={<ElectricityBill />}
              />
              <Route path="/profile" element={<Profile />} />

              <Route path="/transactions" element={<TransactionHistory />} />

              {/* Super Agent ONLY rOUTE */}

              <Route element={<PrivateSuperAgent />}>
                <Route
                  path="/dashboard/add-individual-account"
                  element={<AddIndividualAccount />}
                />
                <Route
                  path="/dashboard/add-bulk-account"
                  element={<AddBulkAccount />}
                />
                <Route
                  path="/dashboard/account-details"
                  element={<AccountDetails />}
                />
                <Route
                  path="/dashboard/transaction-history"
                  element={<TransactionHistory />}
                />

                <Route
                  path="/dashboard/fund-account"
                  element={<FundAccount />}
                />
              </Route>
            </Route>
          </Route>

          <Route path="*" element={<Error404 />} />
        </Route>
        <Route
          path="/mobile-terms-and-condition"
          element={<TermsOfService />}
        />
        <Route path="/mobile-privacy" element={<PrivacyPolicy />} />
      </Routes>
    </Suspense>
  );
};

export { AppRouter };
