import {EditOutlined, UploadOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

export const CreateNewAgentAccountModal = () => {
    const navigate = useNavigate();

    return (
        <div className={'addAgentModal'}>
            <div>Create New Agent Account</div>

            <div>
                <div>Choose an option</div>
                <div>
                    <div onClick={() => {navigate('add-individual-account')}} className={'addAgentCard'}>
                        <EditOutlined style={{fontSize: '2em'}} />
                        <span>Add Individual</span>
                    </div>
                    <div onClick={() => {navigate('add-bulk-account')}} className={'addAgentCard'}>
                        <UploadOutlined style={{fontSize: '2em'}} />
                        <span>Bulk Upload</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
