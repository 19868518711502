import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Footer } from "./component/Footer";
import { Header } from "./component/Header";
type Props = {
  children?: any;
};

const MainLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    if (location.hash === "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location]);

  return (
    <div className="">
      <div className="layout-dark-container ">
        <Header />

        <div className="content-container">
          <Outlet />
        </div>

        <Footer />
        <div className="blue-bar" />
      </div>
    </div>
  );
};

export { MainLayout };
