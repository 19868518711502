import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Footer } from "../component/Footer";
import { LandingHeader } from "../component/LandingHeader";

import "./LandingLayout.scss";
type Props = {
  children?: any;
};

const LandingLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    if (location.hash === "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location]);

  return (
    <div className="">
      <LandingHeader />
      <div className="landing-layout-dark-container">
        <div className="content-container">
          <Outlet />
        </div>

        <Footer />

        <div className="blue-bar" />
      </div>
    </div>
  );
};

export { LandingLayout };
