import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Modal, Table} from "antd";
export const AddBulkAccount = () => {
    const [showInvitationSent, setInvitationSet] = useState(false)
    const [selectedFile, setSelectedFile] = useState<any>()
    const [showingPreview, setShowingPreview] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const uploadFile = ($event: any) => {
        console.log($event.target.files[0], 'QQQQQQ')
        setSelectedFile($event.target.files[0])
    }

    const columns: any = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
        },
    ];

    const data: any[] = [];
    for (let i = 0; i < 46; i++) {
        data.push({
            key: i,
            name: `Sample User ${i}`,
            email: `sampleuser${i}@yopmail.com`,
            phoneNumber: '07034587968',
        });
    }


    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
        <div className={'addBulkAccount'}>
            {
                !showingPreview ?
                    <>
                        <div>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.2871 23.7496C17.1004 23.7502 16.9158 23.709 16.7471 23.629C16.5784 23.549 16.4297 23.4321 16.3121 23.2871L10.2746 15.7871C10.0907 15.5634 9.99023 15.2829 9.99023 14.9934C9.99023 14.7038 10.0907 14.4233 10.2746 14.1996L16.5246 6.69961C16.7368 6.44434 17.0417 6.28381 17.3722 6.25334C17.7027 6.22286 18.0318 6.32494 18.2871 6.53711C18.5424 6.74929 18.7029 7.05417 18.7334 7.38471C18.7638 7.71524 18.6618 8.04434 18.4496 8.29961L12.8621 14.9996L18.2621 21.6996C18.4149 21.8831 18.512 22.1065 18.5419 22.3435C18.5717 22.5804 18.5331 22.8209 18.4305 23.0366C18.328 23.2522 18.1658 23.434 17.9631 23.5604C17.7605 23.6867 17.5259 23.7524 17.2871 23.7496Z" fill="#050649"/>
                            </svg>

                            <div>Create Multiple Agents</div>
                        </div>

                        <div className={'downloadSampleFile'}>
                            <div>Download sample upload file</div>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="29" height="29" rx="1.5" fill="white"/>
                                <rect x="0.5" y="0.5" width="29" height="29" rx="1.5" stroke="#E8E8E8"/>
                                <path d="M17.8128 3.75H6.56245C6.31382 3.75001 6.07537 3.84878 5.89956 4.0246C5.72376 4.20041 5.62499 4.43887 5.625 4.6875V25.3125C5.62499 25.5611 5.72376 25.7996 5.89956 25.9754C6.07537 26.1512 6.31382 26.25 6.56245 26.25H23.4375C23.6862 26.25 23.9246 26.1512 24.1004 25.9754C24.2762 25.7996 24.375 25.5611 24.375 25.3125V10.3125M17.8128 3.75L24.375 10.3125M17.8128 3.75L17.8125 10.3125H24.375" stroke="#D1D6FA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>

                        <label className={'fileUpload'}>
                            <div>File</div>
                            <div>
                                <div className={'browserButton'}>Browse</div>
                                <div>{ selectedFile ? selectedFile.name : 'No file selected'}</div>
                            </div>
                            <div>Upload a csv file</div>
                            <input type="file" accept={'text/csv'} onChange={uploadFile}/>
                        </label>

                        <div className={'actionButtons'}>
                            <button className={'cancel'} onClick={() => navigate('/dashboard')}>Cancel</button>
                            <button className={'preview'} onClick={() => setShowingPreview(true)}>Preview</button>
                        </div>
                    </>
                    :
                    <>
                        <div>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.2871 23.7496C17.1004 23.7502 16.9158 23.709 16.7471 23.629C16.5784 23.549 16.4297 23.4321 16.3121 23.2871L10.2746 15.7871C10.0907 15.5634 9.99023 15.2829 9.99023 14.9934C9.99023 14.7038 10.0907 14.4233 10.2746 14.1996L16.5246 6.69961C16.7368 6.44434 17.0417 6.28381 17.3722 6.25334C17.7027 6.22286 18.0318 6.32494 18.2871 6.53711C18.5424 6.74929 18.7029 7.05417 18.7334 7.38471C18.7638 7.71524 18.6618 8.04434 18.4496 8.29961L12.8621 14.9996L18.2621 21.6996C18.4149 21.8831 18.512 22.1065 18.5419 22.3435C18.5717 22.5804 18.5331 22.8209 18.4305 23.0366C18.328 23.2522 18.1658 23.434 17.9631 23.5604C17.7605 23.6867 17.5259 23.7524 17.2871 23.7496Z" fill="#050649"/>
                            </svg>

                            <div>Agent List</div>
                        </div>

                        <div className={'titleAndActionButton'}>
                            <div>Total Count: <strong>15</strong></div>
                            <Button type="primary" onClick={() => setInvitationSet(true)}>Send Invite</Button>

                            <Modal
                                open={showInvitationSent}
                                footer={null}
                                onCancel={() => setInvitationSet(false)}
                            >
                                <div className={'invitationSent'}>
                                    <div>
                                        <div className={'invitationSentTitle'}>Invitation Sent</div>
                                        <div className={'invitationSentMessage'}>Invites has been sent to the each agent’s email to complete their account setup</div>
                                    </div>

                                    <div>
                                        <Button onClick={() => navigate('/dashboard')} style={{width: '350px'}} type="primary" htmlType="submit">
                                            Done
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        </div>

                        <div>
                            <Table rowSelection={rowSelection} columns={columns} dataSource={data} />
                        </div>
                    </>
            }
        </div>
    );
};
