import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// import { logo, rightLayout2 } from "../../../../assets/images";
import { checkIsHome } from "../../../../utils";
import { fullMaestroLogo } from "../../../../assets/images";

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const [navText, setNav] = useState("");
  const navigate = useNavigate();

  const { pathname } = useLocation();

 
  const isHome = checkIsHome(pathname, window.location.hash);

  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e: any) => {
      const window = e.currentTarget;

      if (window.pageYOffset > 80) {
        setNav("navbar-fixed");
      } else {
        setNav("");
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const redirectLogin = () => {
    navigate("/signin");
  };

  const redirectRegister = () => {
    navigate("/signup");
  };

  

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setSidebar(!sidebar);
  };

  const linkisActive = (to: string) => {

    return window.location.hash === to;
  };

  return (
    <div>
      <nav className={`nav ${navText}`} id="nav">
        <div className="nav-center">
          {/* Nav header */}
          <div className="nav-header">
            <div>
              <img src={fullMaestroLogo} className="nav-logo" alt="" />
            </div>
            <div>
              <button
                id="nav-btn"
                className={`hamburger-button ${menuOpen ? "open" : ""}`}
                onClick={toggleMenu}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </button>
            </div>
          </div>

          {isHome && (
            <>
              <div className="nav-link-container">
                <div className="nav-links">
                  <HashLink to={"/"}>
                    <span
                      className={`${
                        linkisActive("") ? "text-blue-600" : "text-gray-800"
                      }`}
                    >
                      Home
                    </span>
                  </HashLink>

                  <HashLink to={"#services"}>
                    <span
                      className={`${
                        linkisActive("#services")
                          ? "text-blue-600"
                          : "text-gray-800"
                      }`}
                    >
                      Services
                    </span>
                  </HashLink>

                  {/* <HashLink to={"#beAgent"}>
                    <span
                      className={`${
                        linkisActive("#beAgent")
                          ? "text-blue-600"
                          : "text-gray-800"
                      }`}
                    >
                      Become An Agent
                    </span>
                  </HashLink> */}

                  <HashLink to={"#faqs"}>
                    <span
                      className={`${
                        linkisActive("#faqs")
                          ? "text-blue-600"
                          : "text-gray-800"
                      }`}
                    >
                      FAQs
                    </span>
                  </HashLink>

                  <HashLink to={"#contact"}>
                    <span
                      className={`${
                        linkisActive("#contact")
                          ? "text-blue-600"
                          : "text-gray-800"
                      }`}
                    >
                      Contact Us
                    </span>
                  </HashLink>
                </div>
              </div>

              <span className="nav-button-container align-items-center">
                <button className="nav-btn-link" onClick={redirectLogin}>
                  Sign In
                </button>
                <button className="nav-btn-link-2" onClick={redirectRegister}>
                  Sign Up
                </button>
              </span>
            </>
          )}
        </div>
        {/* siderbar */}
        <aside
          className={`sidebar ${sidebar ? "show-sidebar" : ""}`}
          id="sidebar"
        >
          <div>
            {isHome && (
              <>
                <div className="d-flex justify-content-end">
                  <button
                    id="nav-btn"
                    className={`hamburger-button open`}
                    onClick={toggleMenu}
                  >
                    <div className="bar bg-white"></div>
                    <div className="bar bg-white"></div>
                    <div className="bar bg-white"></div>
                  </button>
                </div>
                <ul className="sidebar-menu">
                  <li >
                    <HashLink to={"/"}>Home</HashLink>
                  </li>

                  <li onClick={() => toggleMenu()}>
                    <HashLink to={"/#services"}>Services</HashLink>
                  </li>

                  {/* <li onClick={() => toggleMenu()}>
                    <HashLink to={"/#beAgent"}>Become An Agent</HashLink>
                  </li> */}

                  <li onClick={() => toggleMenu()}>
                    <HashLink to={"/#faqs"}>Faqs</HashLink>
                  </li>

                  <li onClick={() => toggleMenu()}>
                    <HashLink to={"/#contact"}>Contact Us</HashLink>
                  </li>

                  <li onClick={() => toggleMenu()}>
                    <HashLink to={"/signin"}>Login</HashLink>
                  </li>

                  <li onClick={() => toggleMenu()}>
                    <HashLink to={"/signup"}>Register</HashLink>
                  </li>
                </ul>
              </>
            )}
          </div>
        </aside>
      </nav>
    </div>
  );
};

export { Header };
