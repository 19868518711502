import React from "react";
import { useNavigate } from "react-router-dom";

export const Breadcrumb = ({ links }: { links: any[] }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        color: "#050649",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "600",
      }}
     
    >

      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => navigate(-1)}
        className="cursor-pointer"
      >
        <path
          d="M17.2871 23.7496C17.1004 23.7502 16.9158 23.709 16.7471 23.629C16.5784 23.549 16.4297 23.4321 16.3121 23.2871L10.2746 15.7871C10.0907 15.5634 9.99023 15.2829 9.99023 14.9934C9.99023 14.7038 10.0907 14.4233 10.2746 14.1996L16.5246 6.69961C16.7368 6.44434 17.0417 6.28381 17.3722 6.25334C17.7027 6.22286 18.0318 6.32494 18.2871 6.53711C18.5424 6.74929 18.7029 7.05417 18.7334 7.38471C18.7638 7.71524 18.6618 8.04434 18.4496 8.29961L12.8621 14.9996L18.2621 21.6996C18.4149 21.8831 18.512 22.1065 18.5419 22.3435C18.5717 22.5804 18.5331 22.8209 18.4305 23.0366C18.328 23.2522 18.1658 23.434 17.9631 23.5604C17.7605 23.6867 17.5259 23.7524 17.2871 23.7496Z"
          fill="#050649"
        />
      </svg>

      {links.map((link, index) => {
        var showIcon = true;

        if (links.length > 1) {
          if (index + 1 === links.length) {
            showIcon = false;
          } else {
            showIcon = true;
          }
        } else {
          showIcon = true;
        }

        return (
          <div key={index} className="d-flex align-items-center gap-4">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate(link.path)}
            >
              <span
                className={`${
                  showIcon ? "text-gray-500" : "text-federal-blue"
                }`}
              >
                {link.name}
              </span>
            </div>

            {showIcon && (
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.0744L0 10.9256C0 11.3254 0.236844 11.692 0.613658 11.8774C0.991157 12.0628 1.44706 12.0363 1.79669 11.8087L9.55942 6.75661C9.83645 6.57665 10.0007 6.28017 10 5.96438C9.99858 5.64825 9.83207 5.35271 9.55431 5.1742L1.79158 0.18791C1.44044 -0.0373831 0.986688 -0.0618248 0.610557 0.123958C0.235339 0.309997 0 0.675697 0 1.0744Z"
                  fill="#3B4EE4"
                />
              </svg>
            )}
          </div>
        );
      })}
    </div>
  );
};
