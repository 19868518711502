import { Modal } from "antd";
import React from "react";

interface DialogModalProps {
  showModal: boolean;
  setShowModal: (e: boolean) => void;
  children?: React.ReactNode;
  showCloseIcon?: boolean;
}

export const DialogModal: React.FC<DialogModalProps> = ({
  showModal,
  setShowModal,
  children,
  showCloseIcon,
}) => {
  return (
    <Modal
      open={showModal}
      footer={false}
      centered={true}
      onCancel={() => {
        setShowModal(false);
      }}
      className="pin-overlay"
      closeIcon={showCloseIcon || false}
    >
      {children}
    </Modal>
  );
};
