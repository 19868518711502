import { PrivacyPolicyList } from "pageDatas/src/UserHome";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <div className="header">
        <span className=" fs-2qx">Privacy Policy</span>
        <hr />
      </div>

      <div>
        <p>
          Thank you for accessing the MAESTRO MICRO FINANCE BANK privacy page.
          We respect your privacy and want to protect your personal information.
          To learn more, please read through this privacy policy below
        </p>

        <p>
          Our Privacy Policy explains the following;
          <br />
          (i) how we make use of your personal information; 
          <br />
          (ii) the steps we have taken to secure your personal information;
          <br />
          (iii) your options regarding the use of your personal information.
          <br />
          By visiting our page directly or through another site, you accept the
          practices described in this policy
        </p>
      </div>

      <div className="content">
        <ol>
          {PrivacyPolicyList.map((policy) => (
            <li>
              <h1>{policy.title}</h1>
              {policy.desc.map((description) => (
                <p dangerouslySetInnerHTML={{ __html: description }} />
              ))}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
