import { useLocation } from "react-router-dom";

export function checkIsHome(url: string, hash: string) {
  const home = "/";
  const home2 = "/agent-banking";
  const sendMoney = "/#sendMoney";
  const howitworks = "/#howitworks";
  const features = "/#features";
  const mission = "/#mission";
  const contact = "/#contact";

  if (
    url === home ||
    url === home2 ||
    (hash === sendMoney && url === home2) ||
    (hash === howitworks && url === home2) ||
    (hash === features && url === home2) ||
    (hash === mission && url === home2) ||
    (hash === contact && url === home2)
  ) {
    return true;
  }
  return false;
}
