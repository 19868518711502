function referenceGenerator(l:number) {
    const length = 2;
     let result           = '';
     let characters       = '0123456789';
     let charactersLength = characters.length;
     for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     result = Date.now().toString().slice(-9) + result
     return result;//toUpperCase();
  }
  export default referenceGenerator;
  //console.log(makeid(5));