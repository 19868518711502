import axios, { AxiosRequestConfig } from 'axios';
import { baseUrl } from './baseUrl';

// Function to get the token from localStorage
const getToken = (): string | null => localStorage.getItem('token');

// Create a base configuration object with the base URL
const baseConfig: AxiosRequestConfig = {
  baseURL: baseUrl,
};

// Create an Axios instance with the base configuration
const axiosInstance = axios.create(baseConfig);

// Add a request interceptor to update the Authorization header with the latest token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor for error handling and logging
axiosInstance.interceptors.response.use(
  (response) => {
    // Do something with the response data, if needed
    return response;
  },
  (error) => {
    // Handle response errors, log them, and reject the promise
    console.error('Response Error:', error);
    return Promise.reject(error);
  }
);

// Export the configured Axios instance
export { axiosInstance };
