import {
  aboutTheNeedSvg,
  maestroValues,
  maestroValues2,
} from "pageDatas/src/UserHome";
import React from "react";
import "./AboutPage.scss";

const AboutPage: React.FC = () => {
  return (
    <div className="about-container">
      <div className="about-section">
        <h1>About Us</h1>
        <div>
          <p>
            We specialize in providing a comprehensive range of financial
            services tailored to individuals, entrepreneurs, and corporate
            entities.
          </p>
          <p>
            Our dedicated and experienced management team works tirelessly to
            offer innovative financial solutions round the clock.
          </p>
          <div>
            <svg
              width="132"
              height="132"
              viewBox="0 0 132 132"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_4105_1429)">
                <path
                  d="M28.5234 66.3103C28.5234 45.6124 45.3059 28.8335 66.0037 28.8335C86.7016 28.8335 103.484 45.6124 103.484 66.3103C103.484 87.0082 86.7016 103.787 66.0037 103.787C45.3059 103.787 28.5234 87.0082 28.5234 66.3103Z"
                  fill="url(#paint0_linear_4105_1429)"
                  shape-rendering="crispEdges"
                />
                <path
                  d="M28.8641 66.3103C28.8641 45.8006 45.494 29.1742 66.0037 29.1742C86.5135 29.1742 103.143 45.8006 103.143 66.3103C103.143 86.82 86.5135 103.446 66.0037 103.446C45.494 103.446 28.8641 86.82 28.8641 66.3103Z"
                  stroke="#96BDFF"
                  stroke-width="0.6814"
                  shape-rendering="crispEdges"
                />
                <path
                  d="M72.5576 64.1259C70.2401 64.1259 68.0174 63.2053 66.3787 61.5666C64.74 59.9278 63.8193 57.7052 63.8193 55.3877C63.8193 53.0701 64.74 50.8475 66.3787 49.2088C68.0174 47.57 70.2401 46.6494 72.5576 46.6494C74.8751 46.6494 77.0977 47.57 78.7365 49.2088C80.3752 50.8475 81.2959 53.0701 81.2959 55.3877C81.2959 57.7052 80.3752 59.9278 78.7365 61.5666C77.0977 63.2053 74.8751 64.1259 72.5576 64.1259ZM87.8495 81.6025C87.8495 82.7612 87.3892 83.8725 86.5699 84.6919C85.7505 85.5113 84.6392 85.9716 83.4804 85.9716H61.6348C60.476 85.9716 59.3647 85.5113 58.5453 84.6919C57.7259 83.8725 57.2656 82.7612 57.2656 81.6025V79.4179C57.2656 76.521 58.4164 73.7427 60.4648 71.6943C62.5133 69.6459 65.2915 68.4951 68.1885 68.4951H76.9267C79.8236 68.4951 82.6019 69.6459 84.6503 71.6943C86.6988 73.7427 87.8495 76.521 87.8495 79.4179V81.6025Z"
                  fill="#D1D6FA"
                />
                <path
                  d="M59.4502 64.1259C57.1326 64.1259 54.91 63.2053 53.2713 61.5666C51.6325 59.9278 50.7119 57.7052 50.7119 55.3877C50.7119 53.0701 51.6325 50.8475 53.2713 49.2088C54.91 47.57 57.1326 46.6494 59.4502 46.6494C61.7677 46.6494 63.9903 47.57 65.629 49.2088C67.2678 50.8475 68.1884 53.0701 68.1884 55.3877C68.1884 57.7052 67.2678 59.9278 65.629 61.5666C63.9903 63.2053 61.7677 64.1259 59.4502 64.1259ZM74.7421 81.6025C74.7421 82.7612 74.2818 83.8725 73.4624 84.6919C72.6431 85.5113 71.5318 85.9716 70.373 85.9716H48.5273C47.3686 85.9716 46.2573 85.5113 45.4379 84.6919C44.6185 83.8725 44.1582 82.7612 44.1582 81.6025V79.4179C44.1582 76.521 45.309 73.7427 47.3574 71.6943C49.4059 69.6459 52.1841 68.4951 55.081 68.4951H63.8193C66.7162 68.4951 69.4945 69.6459 71.5429 71.6943C73.5913 73.7427 74.7421 76.521 74.7421 79.4179V81.6025Z"
                  fill="#3B4EE4"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_4105_1429"
                  x="0.586023"
                  y="0.896082"
                  width="130.836"
                  height="130.828"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feMorphology
                    radius="0.6814"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_4105_1429"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="13.628" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4105_1429"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_4105_1429"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_4105_1429"
                  x1="80.7719"
                  y1="46.8737"
                  x2="43.2639"
                  y2="38.3611"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.350099" stop-color="#6BA1FF" />
                  <stop offset="1" stop-color="#6BA1FF" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>

      <div className="maestro-values-container">
        <div className="values-content">
          <h1>Maestro Values</h1>

          <div>
            {maestroValues.map((valuesItem) => (
              <div className="values" key={valuesItem.id}>
                <div>
                  {valuesItem.icon}
                  <h1 className="fs-base text-foundation-blue-200">
                    {valuesItem.title}
                  </h1>
                </div>
                <p className="text-gray-800 text-center fs-8">
                  {valuesItem.desc}
                </p>
              </div>
            ))}
          </div>

          <div>
            {maestroValues2.map((valuesItem) => (
              <div className="values" key={valuesItem.id}>
                <div>
                  {valuesItem.icon}
                  <h1 className="fs-base text-foundation-blue-200">
                    {valuesItem.title}
                  </h1>
                </div>
                <p className="text-gray-800 text-center fs-8">
                  {valuesItem.desc}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="the-need-section">
        <div>
          <h1>THE NEED</h1>
          <hr />
        </div>

        <div>
          <div>
            <p>
              At Maestro Microfinance Bank, we recognize that the digitization
              of services is pivotal in driving economic progress. Leveraging
              advanced technology, we deliver our products and services, viewing
              it as the swiftest and most cost-effective approach to achieving
              our goal of promoting financial inclusion and serving the
              unbanked.
            </p>
            <p>
              Our comprehensive digital platform empowers clients to budget
              intelligently, save effectively, and make seamless payments.
              Additionally, we facilitate hassle-free digital account openings
              and issue virtual cards, all contributing to our vision of a
              banking environment that is seamless, accessible, and convenient
              for all.
            </p>
          </div>

          <div>{aboutTheNeedSvg}</div>
        </div>
      </div>

      <div className="mission-section">
        <div>
          <div className="vision-mision">
            <h1>Vision</h1>
            <p>
              To achieve financial inclusion for all through continuous
              innovation.
            </p>
          </div>
          <div className="vl" />
          <div className="vision-mision">
            <h1>Mission</h1>
            <p>
              Providing comprehensive financial services to enable the unbanked
              and underbanked  to realize their potential using technology.{" "}
            </p>
          </div>
        </div>

        <div>
          <hr className="line" />
          <h1>Legal approval</h1>
          <hr className="line" />
        </div>
        <div>
          <p>
            Maestro Microfinance Bank, established on June 8, 2022, under the
            Companies and Allied Matters Act, 2020, received approval from the
            Central Bank of Nigeria (CBN) in July 2023 to operate as a Tier 2
            Unit Microfinance bank.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
