import { TermsAndConditionList } from "pageDatas/src/UserHome";
import "./PrivacyPolicy.scss";

const TermsOfService = () => {
  return (
    <div className="privacy-container">
      <div className="header">
        <span className=" fs-2qx">TERM OF SERVICE AGREEMENT</span>
        <hr />
      </div>

      <div>
        <p>
          Please read these terms of service ("terms") carefully before using
          this service Maestro MFB. These Terms of Service set forth the
          conditions upon which MAESTRO MICRO FINANCE BANK provides its service.
          Your consent to use and comply with this Agreement is expressed by
          clicking the pop up that comes up upon opening this page, which simply
          means “I have read and agree to the terms of service”. Every time you
          use the service, at which point you agree to be bound by this Terms of
          Service Agreement. The MAESTRO MICRO FINANCE BANK reserves the right
          to revise the Terms of Service and by continuing to use the service
          you agree to be automatically bound by the revised Terms of Service.
        </p>
      </div>

      <div className="content">
        <ol>
          {TermsAndConditionList.map((policy) => (
            <li>
              <h1>{policy.title}</h1>
              {policy.desc.map((description) => (
                <p>{description}</p>
              ))}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default TermsOfService;
