import { Navigate, Outlet } from "react-router-dom";
import { AgentLayout } from "../../components/src/layout/AgentLayout";
import { SuperAgentLayout } from "../../components/src/layout/SuperAgentLayout";
import Dashboard from "../../pages/Dashboard";
import SuperAgentDashboard from "../../pages/Dashboard/SuperAgentDashboard";
import { getLocalData, isEmpty, userIsSuperAgent } from "../../utils";
import { getToken } from "../../utils/src/getSavedData";
type Props = {
  children: any;
  roles: string;
};

const PrivateRoutes = () => {
  const token = getToken();

  return !isEmpty(token) ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/signin",
      }}
    />
  );
};
export default PrivateRoutes;

export const PrivateSuperAgent = () => {
  return userIsSuperAgent() ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/dashboard",
      }}
    />
  );
};

export const UserLayoutPick = () => {
  return userIsSuperAgent() ? <SuperAgentLayout /> : <AgentLayout />;
};

export const UserDashboardPick = () => {
  return userIsSuperAgent() ? <SuperAgentDashboard /> : <Dashboard />;
};
