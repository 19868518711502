import React, {useState} from "react";

export const TabSwitcher: React.FC<any> = ({ tabs, onTabClick }) => {
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const handleTabClick = (tab: any) => {
        setCurrentTab(tab);
        onTabClick(tab);
    };

    return (
        <div className={'tabSwitcher'}>
            {tabs.map((tab: any) => (
                <div
                    key={tab}
                    onClick={() => handleTabClick(tab)}
                    className={currentTab === tab ? 'tabSwitcherActive' : ''}
                >
                    {tab}
                </div>
            ))}
        </div>
    );
};
