import React, { useEffect, useState } from "react";
import { Button, Modal, Switch, Table } from "antd";
import { EmptyState } from "../../components/src/layout/component/EmptyState";
import { CreateNewAgentAccountModal } from "../SuperAgent/AccountCreation/CreateNewAgentAccountModal";
import { useNavigate } from "react-router-dom";
import {
  useRequestGetSuperAgentMetrics,
  useRequestGetSuperAgentAgents,
} from "../Auth/useAuth";
import { getLocalData } from "../../utils";

const SuperAgentDashboard: React.FC = () => {
  const [showAddAgentModal, setAddAgentModal] = useState(false);
  const navigate = useNavigate();

  const { data, error, mutate, isLoading } = useRequestGetSuperAgentMetrics();
  const {
    data: dataAgents,
    error: errorAgent,
    mutate: mutateAgent,
    isLoading: isLoadingAgent,
  } = useRequestGetSuperAgentAgents();

  useEffect(() => {
    mutate();
    mutateAgent(getLocalData("authData")?.phoneNumber);
  }, []);

  const dataSource: any = [
    {
      agentName: "John Doe",
      emailAddress: "johndoe@gmail.com",
      phoneNumber: "+1 1234567890",
      address: "Street 1, City",
      totalBalance: "N200,000",
      action: "Action",
    },
    {
      agentName: "Jane Doe",
      emailAddress: "janedoe@yahoo.com",
      phoneNumber: "+1 9876543210",
      address: "Street 2, City",
      totalBalance: "N200,000",
      action: "Action",
    },
    {
      agentName: "Alice Smith",
      emailAddress: "alicesmith@hotmail.com",
      phoneNumber: "+1 2345678901",
      address: "Street 3, City",
      totalBalance: "N200,000",
      action: "Action",
    },
    {
      agentName: "Bob Johnson",
      emailAddress: "bobjohnson@example.com",
      phoneNumber: "+1 8765432109",
      address: "Street 4, City",
      totalBalance: "N200,000",
      action: "Action",
    },
    {
      agentName: "Charlie Brown",
      emailAddress: "charliebrown@test.com",
      phoneNumber: "+1 3456789012",
      address: "Street 5, City",
      totalBalance: "N200,000",
      action: "Action",
    },
  ];

  const columns: any[] = [
    {
      title: "Agent name",
      dataIndex: "agentName",
      key: "agentName",
    },
    {
      title: "Email address",
      dataIndex: "emailAddress",
      key: "emailAddress",
    },
    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    // {
    //     title: 'Address',
    //     dataIndex: 'address',
    //     key: 'address',
    // },
    {
      title: "Total Balance",
      dataIndex: "totalBalance",
      key: "totalBalance",
    },
    {
      title: "Activation Status",
      dataIndex: "activationStatus",
      key: "activationStatus",
      render: (text: any, record: any): any => (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any): any => (
        <div
          className={"viewDetailsButton"}
          onClick={() => navigate("/dashboard/account-details")}
        >
          View Details
        </div>
      ),
    },
  ];

  return (
    <main className={"superAgentDashboard"}>
      <div className={"completeRegistrationNotice"}>
        {(Number(getLocalData("authData")?.profileID) - 1) * 2 * 10 < 100 && (
          <div>
            <div>
              <strong>Complete your Registration</strong>
              <div>
                Please update your information to activate your account.
              </div>
            </div>
            <div
              onClick={() => navigate("/profile")}
              className={"completeRegistrationLink"}
            >
              <span>Complete Registration</span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4023 11.142C14.0063 10.5002 14.0063 9.49915 13.4023 8.8574L7.27318 2.34521C6.95775 2.01006 6.43035 1.99408 6.09521 2.30951C5.76006 2.62494 5.74408 3.15234 6.05951 3.48748L12.1886 9.99968L6.05951 16.5119C5.74408 16.847 5.76006 17.3744 6.09521 17.6898C6.43035 18.0053 6.95775 17.9893 7.27318 17.6541L13.4023 11.142Z"
                  fill="#6775EA"
                />
              </svg>
            </div>
          </div>
        )}

        <div>
          <svg
            className={"shilledLogo"}
            width="140"
            height="109"
            viewBox="0 0 140 109"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_1973_28340)">
              <path
                d="M85.7273 65.375C88.0053 63.0969 88.0053 59.4034 85.7273 57.1254C83.4492 54.8473 79.7557 54.8473 77.4777 57.1254L66.1684 68.4346L64.524 66.7903C62.246 64.5123 58.5525 64.5123 56.2744 66.7903C53.9964 69.0684 53.9964 72.7618 56.2744 75.0399L62.0436 80.809C64.3216 83.0871 68.0151 83.0871 70.2931 80.809L85.7273 65.375Z"
                fill="#6775EA"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.6779 11.7763C73.1078 9.54463 66.8922 9.54463 61.3221 11.7763L40.1671 20.252C40.1013 20.2784 40.036 20.3059 39.9712 20.3347L30.9439 24.3391C23.0268 27.8511 17.4992 35.5838 17.5 44.5884C17.501 56.0639 18.3283 75.4872 23.7233 88.8141C27.3105 97.6754 33.7713 105.356 40.3377 111.493C46.9512 117.675 53.997 122.592 59.1795 125.864C65.8209 130.058 74.1791 130.058 80.8205 125.864C86.003 122.592 93.0488 117.675 99.6623 111.493C106.229 105.356 112.69 97.6754 116.277 88.8141C121.672 75.4872 122.499 56.0639 122.5 44.5884C122.501 35.5838 116.973 27.8511 109.056 24.3391L100.029 20.3347C99.964 20.3059 99.8987 20.2784 99.8329 20.252L78.6779 11.7763ZM65.661 22.6061C68.4461 21.4903 71.5539 21.4903 74.3389 22.6061L95.3953 31.0423L104.325 35.0036C108.343 36.786 110.834 40.5353 110.833 44.5875C110.832 56.1422 109.909 73.4526 105.463 84.4364C102.768 91.0933 97.6426 97.4116 91.6956 102.97C85.7958 108.485 79.4009 112.963 74.5916 115.999C71.7553 117.79 68.2447 117.79 65.4084 115.999C60.5991 112.963 54.2042 108.485 48.3044 102.97C42.3574 97.4116 37.2323 91.0933 34.5374 84.4364C30.091 73.4526 29.1676 56.1422 29.1667 44.5875C29.1663 40.5353 31.6566 36.786 35.6746 35.0036L44.6047 31.0423L65.661 22.6061Z"
                fill="#6775EA"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_1973_28340"
                x="13.5"
                y="10.1025"
                width="109"
                height="126.906"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="-4" dy="8" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.960784 0 0 0 0 0.4 0 0 0 0 0.188235 0 0 0 0.37 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_1973_28340"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_1973_28340"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>

      <div className={"titleAndCards"}>
        <div className={"titleAndActionButton"}>
          <div>Overview</div>
          <Button type="primary" onClick={() => setAddAgentModal(true)}>
            Add New Agent
          </Button>

          <Modal
            open={showAddAgentModal}
            footer={null}
            onCancel={() => setAddAgentModal(false)}
          >
            <CreateNewAgentAccountModal />
          </Modal>
        </div>

        <div className={"cardRow"}>
          <div className={"dashboardCard"}>
            <div>
              <div>Number of agents</div>
              <div className={"badge"}>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.64645 7.47983C2.45118 7.67509 2.45118 7.99167 2.64645 8.18693C2.84171 8.38219 3.15829 8.38219 3.35355 8.18693L5.03388 6.5066L5.68373 7.03731C5.88793 7.20407 6.18653 7.18363 6.36609 6.9906L8.00142 5.23262L8.63523 5.90868C8.8241 6.11014 9.14051 6.12035 9.34197 5.93148C9.54343 5.74262 9.55363 5.4262 9.36477 5.22474L8.36477 4.15808C8.27001 4.057 8.13757 3.99978 7.99903 4.00005C7.86049 4.00032 7.72827 4.05806 7.63391 4.15949L5.95319 5.96627L5.31627 5.44611C5.11744 5.28374 4.82796 5.29831 4.64645 5.47983L2.64645 7.47983Z"
                    fill="#3B4EE4"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.5 1.5C1.39543 1.5 0.5 2.39543 0.5 3.5V8.5C0.5 9.60457 1.39543 10.5 2.5 10.5H9.5C10.6046 10.5 11.5 9.60457 11.5 8.5V3.5C11.5 2.39543 10.6046 1.5 9.5 1.5H2.5ZM1.5 3.5C1.5 2.94772 1.94772 2.5 2.5 2.5H9.5C10.0523 2.5 10.5 2.94772 10.5 3.5V8.5C10.5 9.05228 10.0523 9.5 9.5 9.5H2.5C1.94772 9.5 1.5 9.05228 1.5 8.5V3.5Z"
                    fill="#3B4EE4"
                  />
                </svg>

                <span>0%</span>
              </div>
            </div>
            <div> {data?.superAgentBestAgents?.length} </div>
          </div>

          <div className={"dashboardCard"}>
            <div>
              <div>Summary of transactions</div>
              <div className={"badge"}>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.64645 7.47983C2.45118 7.67509 2.45118 7.99167 2.64645 8.18693C2.84171 8.38219 3.15829 8.38219 3.35355 8.18693L5.03388 6.5066L5.68373 7.03731C5.88793 7.20407 6.18653 7.18363 6.36609 6.9906L8.00142 5.23262L8.63523 5.90868C8.8241 6.11014 9.14051 6.12035 9.34197 5.93148C9.54343 5.74262 9.55363 5.4262 9.36477 5.22474L8.36477 4.15808C8.27001 4.057 8.13757 3.99978 7.99903 4.00005C7.86049 4.00032 7.72827 4.05806 7.63391 4.15949L5.95319 5.96627L5.31627 5.44611C5.11744 5.28374 4.82796 5.29831 4.64645 5.47983L2.64645 7.47983Z"
                    fill="#3B4EE4"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.5 1.5C1.39543 1.5 0.5 2.39543 0.5 3.5V8.5C0.5 9.60457 1.39543 10.5 2.5 10.5H9.5C10.6046 10.5 11.5 9.60457 11.5 8.5V3.5C11.5 2.39543 10.6046 1.5 9.5 1.5H2.5ZM1.5 3.5C1.5 2.94772 1.94772 2.5 2.5 2.5H9.5C10.0523 2.5 10.5 2.94772 10.5 3.5V8.5C10.5 9.05228 10.0523 9.5 9.5 9.5H2.5C1.94772 9.5 1.5 9.05228 1.5 8.5V3.5Z"
                    fill="#3B4EE4"
                  />
                </svg>

                <span>0%</span>
              </div>
            </div>
            <div>{data?.totalTransactions}</div>
          </div>

          <div className={"dashboardCard"}>
            <div>
              <div>Money in</div>
              <div className={"badge"}>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.64645 7.47983C2.45118 7.67509 2.45118 7.99167 2.64645 8.18693C2.84171 8.38219 3.15829 8.38219 3.35355 8.18693L5.03388 6.5066L5.68373 7.03731C5.88793 7.20407 6.18653 7.18363 6.36609 6.9906L8.00142 5.23262L8.63523 5.90868C8.8241 6.11014 9.14051 6.12035 9.34197 5.93148C9.54343 5.74262 9.55363 5.4262 9.36477 5.22474L8.36477 4.15808C8.27001 4.057 8.13757 3.99978 7.99903 4.00005C7.86049 4.00032 7.72827 4.05806 7.63391 4.15949L5.95319 5.96627L5.31627 5.44611C5.11744 5.28374 4.82796 5.29831 4.64645 5.47983L2.64645 7.47983Z"
                    fill="#3B4EE4"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.5 1.5C1.39543 1.5 0.5 2.39543 0.5 3.5V8.5C0.5 9.60457 1.39543 10.5 2.5 10.5H9.5C10.6046 10.5 11.5 9.60457 11.5 8.5V3.5C11.5 2.39543 10.6046 1.5 9.5 1.5H2.5ZM1.5 3.5C1.5 2.94772 1.94772 2.5 2.5 2.5H9.5C10.0523 2.5 10.5 2.94772 10.5 3.5V8.5C10.5 9.05228 10.0523 9.5 9.5 9.5H2.5C1.94772 9.5 1.5 9.05228 1.5 8.5V3.5Z"
                    fill="#3B4EE4"
                  />
                </svg>

                <span>0</span>
              </div>
            </div>
            <div>₦{data?.totalDeposits}</div>
          </div>

          <div className={"dashboardCard"}>
            <div>
              <div>Money out</div>
              <div className={"badge"}>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.64645 7.47983C2.45118 7.67509 2.45118 7.99167 2.64645 8.18693C2.84171 8.38219 3.15829 8.38219 3.35355 8.18693L5.03388 6.5066L5.68373 7.03731C5.88793 7.20407 6.18653 7.18363 6.36609 6.9906L8.00142 5.23262L8.63523 5.90868C8.8241 6.11014 9.14051 6.12035 9.34197 5.93148C9.54343 5.74262 9.55363 5.4262 9.36477 5.22474L8.36477 4.15808C8.27001 4.057 8.13757 3.99978 7.99903 4.00005C7.86049 4.00032 7.72827 4.05806 7.63391 4.15949L5.95319 5.96627L5.31627 5.44611C5.11744 5.28374 4.82796 5.29831 4.64645 5.47983L2.64645 7.47983Z"
                    fill="#3B4EE4"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.5 1.5C1.39543 1.5 0.5 2.39543 0.5 3.5V8.5C0.5 9.60457 1.39543 10.5 2.5 10.5H9.5C10.6046 10.5 11.5 9.60457 11.5 8.5V3.5C11.5 2.39543 10.6046 1.5 9.5 1.5H2.5ZM1.5 3.5C1.5 2.94772 1.94772 2.5 2.5 2.5H9.5C10.0523 2.5 10.5 2.94772 10.5 3.5V8.5C10.5 9.05228 10.0523 9.5 9.5 9.5H2.5C1.94772 9.5 1.5 9.05228 1.5 8.5V3.5Z"
                    fill="#3B4EE4"
                  />
                </svg>

                <span>0</span>
              </div>
            </div>
            <div>₦{data?.totalWithdrawals}</div>
          </div>
        </div>
      </div>

      <div className={"agentListSection"}>
        <div className={"tableHeader"}>
          <div>Agent List</div>

          <div>
            <Button className={"filter"} ghost>
              Filter
            </Button>
            <Button className={"exportToCsv"} ghost>
              Export as CSV
            </Button>
          </div>
        </div>

        <div className={"superAgentTable"}>
          {dataAgents?.data ? (
            <>
              {" "}
              <Table
                dataSource={[]}
                columns={columns}
                locale={{ emptyText: EmptyState }}
              />
              ;
            </>
          ) : (
            <>
              <Table
                dataSource={dataAgents?.data}
                columns={columns}
                locale={{ emptyText: EmptyState }}
              />
              ;
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default SuperAgentDashboard;
